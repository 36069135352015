<template>
  <v-container>
    <v-card>
      <v-card-title>Agregar Terapia Oportunista</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="isFormValid"
          @submit.prevent="guardarTerapia"
        >
          <v-text-field
            v-model="nuevaTerapia.dis_d"
            label="Fecha Diagnostico"
            type="date"
            :rules="[(v) => !!v || 'Fecha de diagnostico es requerida']"
            required
          />
          <v-text-field
            v-model="nuevaTerapia.Fecha_inicio"
            label="Fecha Inicio"
            type="date"
            :rules="[(v) => !!v || 'Fecha de inicio es requerida']"
            required
          />
          <v-text-field
            v-model="nuevaTerapia.Fecha_fin"
            label="Fecha Fin"
            type="date"
            :rules="[(v) => !!v || 'Fecha de fin es requerida']"
            required
          />
          <v-text-field v-model="nuevaTerapia.local_oth" label="local_oth" />
          <v-text-field v-model="nuevaTerapia.dis_oth" label="Observación" />
          <p class="pregunta-poll">
            Indica cuales son las enfermedades oportunistas a tratar
            <span class="sub-pregunta-poll">
              (Marca todas las que correspondan)
            </span>
          </p>
          <v-checkbox
            v-for="n in this.ENFERMEDADES_OPO"
            :key="n"
            :label="`${n}`"
            :value="n"
            color="#1dc6d8"
            v-model="nuevaTerapia.opo"
            :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
            class="my-0"
          />
          <!-- Sección de drogas -->
          <v-card class="mt-4 mb-4" outlined>
            <v-card-title>Drogas</v-card-title>
            <v-card-text>
              <v-row
                v-for="(droga, index) in nuevaTerapia.drogas"
                :key="index"
                align="center"
              >
                <v-col cols="12" sm="3">
                  <v-select
                    v-model="droga.nombre"
                    :items="drogasss"
                    label="Nombre"
                    return-object
                    single-line
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model.number="droga.mg"
                    label="mg"
                    type="number"
                    :rules="[(v) => v > 0 || 'mg debe ser mayor que 0']"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model.number="droga.posologia"
                    label="Posología"
                    type="number"
                    :rules="[(v) => v > 0 || 'Posología debe ser mayor que 0']"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn color="error" @click="eliminarDroga(index)">
                    Eliminar
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn color="success" @click="agregarDroga">Agregar Droga</v-btn>
            </v-card-text>
          </v-card>
          <v-btn type="submit" color="primary" class="mr-4">
            {{ isSaving ? "Guardando..." : "Guardar Terapia" }}
          </v-btn>
          <v-btn @click="cancelar" color="error"> Cancelar </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AddPOP",
  data() {
    return {
      isFormValid: false,
      isSaving: false,
      nuevaTerapia: {
        dis_d: "",
        Fecha_inicio: "",
        Fecha_fin: "",
        dis_oth: "",
        local_oth: "",
        drogas: [],
        opo: [],
      },
      ENFERMEDADES_OPO: [
        "Neumonía",
        "Infecciones por Salmonella",
        "Candidiasis (algodoncillo)",
        "Toxoplasmosis",
        "Tuberculosis (TB)",
      ],
    };
  },
  computed: {
    ...mapState("patients", ["patient"]),
    drogasss() {
      return this.$store.getters["datosVariables/drogas_pop_choices"];
    },
  },
  methods: {
    ...mapActions("terapias", ["crearTerapia", "crearTerapiaPop"]),
    ...mapActions("patients", ["getPatient"]),
    async guardarTerapia() {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        try {
          const { rut } = this.patient;
          await this.crearTerapiaPop({
            ...this.nuevaTerapia,
            paciente: rut,
          });
          this.$store.dispatch("toast/showToast", {
            message: `Terapia creada con éxito`,
            color: "success",
          });
        } catch (error) {
          console.error("Error al crear la terapia:", error);
          // Aquí podrías mostrar un mensaje de error al usuario
        } finally {
          this.isSaving = false;
        }
      }
    },
    cancelar() {
      this.$emit("close-dialog");
    },
    agregarDroga() {
      this.nuevaTerapia.drogas.push({
        nombre: "asd" /* "", */,
        mg: 100 /* null, */,
        posologia: 3 / 8 /* null, */,
      });
    },
    eliminarDroga(index) {
      this.nuevaTerapia.drogas.splice(index, 1);
    },
  },
  async created() {
    await this.getPatient(this.$route.params["id"]);
    this.agregarDroga();
  },
};
</script>
