<template>
  <v-container class="pa-5">
    <h1 class="titulo-seccion-monitoreo">Otras enfermedades</h1>
    <v-row>
      <v-col class="subtitulo-seccion-monitoreo">Fecha diagnóstico</v-col>
      <v-col class="subtitulo-seccion-monitoreo">Nombre enfermedad</v-col>
      <v-col class="subtitulo-seccion-monitoreo justify-center">Acciones</v-col>
    </v-row>
    <v-container
      v-if="otras_enfermedades.length > 0"
      class="mt-3 overflow-auto elevation-1 rounded-lg"
      style="max-height: 400px"
    >
      <v-form
        v-for="(otra_enfermedad, index) in otras_enfermedades"
        :key="index"
        ref="form"
        class="d-flex"
      >
        <v-col cols="4">
          <v-menu
            v-model="menuState[index]"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                label="Fecha de diagnóstico"
                placeholder="Fecha de diagnóstico"
                v-model="otra_enfermedad.date"
                :rules="[
                  (v) =>
                    (v !== null && v !== undefined && v !== '') ||
                    'Falta fecha de diagnóstico',
                ]"
                required
              />
            </template>
            <v-date-picker
              v-model="otra_enfermedad.date"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @input="menuState[index] = false"
            />
          </v-menu>
        </v-col>

        <v-col cols="4">
          <v-text-field
            :rules="[
              (v) =>
                (v !== null && v !== undefined && v !== '') ||
                'Falta la enfermedad',
            ]"
            label="Nombre enfermedad"
            v-model="otra_enfermedad.nombre"
          />
        </v-col>
        <v-col class="d-flex justify-center">
          <v-btn
            type="submit"
            class="orange-button ma-5"
            @click.prevent="addEnfermedad(otra_enfermedad, index)"
          >
            Guardar
          </v-btn>
          <v-btn
            class="delete-button ma-5"
            @click.prevent="deleteEnfermedad(otra_enfermedad)"
          >
            Borrar
          </v-btn>
        </v-col>
      </v-form>
    </v-container>
    <v-row class="d-flex justify-end">
      <v-btn
        class="blue-button ma-5"
        @click="addFormEnfermedad"
        :disabled="new_form"
      >
        +Agregar Enfermedad
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OtrasEnfermedadesComponente",
  data() {
    return {
      otras_enfermedades: [],
      opcionesEnfermedades: [],
      new_form: false,
    };
  },
  methods: {
    async addEnfermedad(item, index) {
      const valid = this.$refs.form[index] && this.$refs.form[index].validate();
      if (!valid) return;
      const { id, nombre, date } = item;
      if (id) {
        try {
          await this.$store.dispatch("patients/updateEnfermedadPaciente", {
            id,
            body: { nombre, date },
          });
        } catch (error) {
          console.error("Error updating enfermedad:", error);
        }
      } else {
        const response = await this.$store.dispatch(
          "patients/addEnfermedadPaciente",
          { nombre, date, paciente: this.rut }
        );
        if (response.status === 201) {
          item.id = response.data.id;
          this.new_form = false;
        }
      }
    },
    addFormEnfermedad() {
      this.otras_enfermedades.push({
        id: false,
        date: "",
        nombre: "",
      });
      this.new_form = true;
    },
    deleteEnfermedad(otra_enfermedad) {
      const { id } = otra_enfermedad;

      if (id) {
        this.$store.dispatch("patients/deleteEnfermedadPaciente", { id });
      } else {
        this.new_form = false;
      }
      this.otras_enfermedades = this.otras_enfermedades.filter(
        (otras_enfermedades) => otras_enfermedades.id !== id
      );
    },
    transform(lista, categoria = "") {
      if (lista) {
        if (categoria !== "") {
          lista = lista.filter((item) => item.categoria == categoria);
        }
        return lista.map((item) => {
          return {
            value: item.id,
            text: item.tipo_especifico,
          };
        });
      } else {
        return [];
      }
    },
  },
  computed: {
    menuState() {
      return this.otras_enfermedades.map(() => false);
    },
    rut() {
      return this.$store.getters["patients/getPatient"].rut;
    },
  },
  async created() {
    this.opcionesEnfermedades =
      this.$store.getters["patients/getOpcionesEnfermedades"];
    if (!this.opcionesEnfermedades) {
      await this.$store.dispatch("patients/getOpcionesEnfermedades");
      this.opcionesEnfermedades =
        this.$store.getters["patients/getOpcionesEnfermedades"];
    }
    try {
      const { id } = this.$route.params;
      const response = await this.$store.dispatch(
        "patients/getEnfermedadesPaciente",
        {
          codigoconasida: id,
          tipo_enfermedad: "otra_enfermedad",
        }
      );
      this.otras_enfermedades = response.data.results;

      if (this.otras_enfermedades.length === 0) {
        this.addFormEnfermedad();
      }
    } catch (error) {
      console.error("Error loading enfermedades:", error);
    }
  },
};
</script>
