<template>
  <v-container class="contenedores-monitoreo">
    <v-card flat>
      <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
        <v-card-title
          class="d-flex justify-space-between titulo-seccion-monitoreo"
        >
          Diagnóstico
          <v-btn outlined color="#1DC6D8">
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
            Actualizar
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="subtitulo-seccion-monitoreo">
          Verifique si los datos son correctos
        </v-card-subtitle>
        <v-form @submit.prevent="sendData" ref="form">
          <v-row>
            <v-col>
              <v-menu
                v-model="menu_d_diagnosis"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha Dianostico SIDA"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    v-model="patient.diagnosis_d"
                    :disabled="patient.aids_y != 1"
                    :rules="[validateDiagnosis]"
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="patient.diagnosis_d"
                  :max="maxDate"
                  min="1950-01-01"
                  @input="menu_d_diagnosis = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-select
                class="modify-input"
                required
                :rules="[
                  (v) => (v !== null && v !== '') || 'Falta diagnóstico SIDA',
                ]"
                :items="[
                  { value: 1, text: 'Sí' },
                  { value: 2, text: 'No' },
                  { value: 9, text: 'Desconocido' },
                ]"
                label="Diagnostico SIDA"
                outlined
                v-model="patient.aids_y"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                class="modify-input"
                required
                :rules="[(v) => !!v || 'Falta Mode']"
                :items="[
                  { value: 1, text: 'Contacto Homosexual' },
                  { value: 2, text: 'Usuario de drogas inyectables' },
                  { value: 4, text: 'Hemofílico' },
                  {
                    value: 5,
                    text: 'Transfusión, no relacionado con hemofilia',
                  },
                  { value: 6, text: 'Contacto Heterosexual' },
                  { value: 8, text: 'Perinatal' },
                  { value: 9, text: 'Contacto Sexual' },
                  { value: 10, text: 'Bisexual' },
                  { value: 90, text: 'Otro' },
                  { value: 99, text: 'Desconocido' },
                ]"
                label="Modo probable de infección"
                outlined
                v-model="patient.mode"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Otro modo de infección"
                v-model="patient.mode_oth"
                outlined
                clearable
                :disabled="patient.mode != 90"
              />
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn type="submit" color="#F4A261" class="white--text px-10">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
      <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
        <CDCComponente />
      </v-sheet>
      <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
        <v-col>
          <comorbilidades-componente />
          <otras-enfermedades-componente />
        </v-col>
      </v-sheet>
    </v-card>
  </v-container>
</template>
<script>
import CDCComponente from "./CDCComponente.vue";
import ComorbilidadesComponente from "./ComorbilidadesComponente.vue";
import OtrasEnfermedadesComponente from "./OtrasEnfermedadesComponente.vue";
export default {
  components: {
    CDCComponente,
    ComorbilidadesComponente,
    OtrasEnfermedadesComponente,
  },
  name: "DiagnosticoComponente",
  data() {
    return {
      patient: {},
      menu_d_diagnosis: false,
    };
  },
  computed: {
    maxDate() {
      return new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString();
    },
  },
  methods: {
    validateDiagnosis(value) {
      // Si `aids_y` no es 1, no es necesario validar.
      if (this.patient.aids_y !== 1) return true;

      // Si `aids_y` es 1, verifica que `diagnosis_d` no esté vacío.
      return (
        (value !== null && value !== undefined && value !== "") ||
        "Debe ingresar una fecha de diagnóstico."
      );
    },
    async sendData() {
      const valid = this.$refs.form.validate();
      if (!valid) return;
      if (this.patient.mode != 90 && this.patient.mode_oth) {
        return this.$store.dispatch("toast/showToast", {
          message: `Si hay descripcion de modo, el valor del modo de transmisión tiene que ser 'Otro'`,
          tipo: "error",
        });
      } else if (this.patient.mode == 90 && !this.patient.mode_oth) {
        return this.$store.dispatch("toast/showToast", {
          message: `Si el valor del modo de transmisión es 'Otro', debe ingresar una descripción`,
          tipo: "error",
        });
      }
      await this.$store.dispatch("patients/updatePatient", this.patient);
    },
  },
  watch: {
    "patient.aids_y"(value) {
      if (value != 1) {
        this.patient.diagnosis_d = null;
      }
    },
  },
  async created() {
    let patient = this.$store.getters["patients/getPatient"];
    if (!patient) {
      patient = await this.$store.dispatch("patients/loadDatosPersonales", {
        codigoconasida: this.$route.params.id,
      });
    }
    this.$store.dispatch("patients/getCDCStageData", { rut: patient.rut });
    this.patient = patient;
  },
};
</script>

<style>
.contenedores-monitoreo {
  padding: 20px;
}
</style>
