<template>
  <v-container class="contenedores-monitoreo">
    <v-card flat>
      <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
        <v-card-title class="d-flex justify-space-between">
          Variables registradas
          <v-btn outlined color="#1DC6D8">
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
            Actualizar
          </v-btn>
        </v-card-title>
        <v-card-subtitle>Verifique si los datos son correctos</v-card-subtitle>
        <v-form @submit.prevent="sendData" ref="form">
          <v-row>
            <v-col>
              <label for="codigoconasida" class="label-inputs">
                Codigo CONASIDA
              </label>
              <v-text-field
                id="codigoconasida"
                class="modify-input"
                outlined
                readonly
                v-model="this.patient.codigoconasida"
                disabled
              />
            </v-col>
            <v-col>
              <label for="birth_day" class="label-inputs">
                Fecha de nacimiento
              </label>
              <v-menu
                v-model="birth_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="birth_day"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    v-model="patient.birth_d"
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="patient.birth_d"
                  :max="maxDate"
                  min="1950-01-01"
                  @input="birth_date_menu = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <label for="edad" class="label-inputs">Edad</label>
              <v-text-field
                id="edad"
                v-model="edad"
                required
                hide-details
                single-line
                type="text"
                outlined
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong class="label-inputs">Situación actual paciente</strong>
              <v-select
                class="modify-input mt-3"
                label="Condición especial del paciente"
                v-model="patient.condicion_especial"
                :items="situation_choices"
                item-text="label"
                item-value="value"
                outlined
                no-data-text="No hay opciones disponibles aún"
              />
            </v-col>
            <v-col>
              <strong class="label-inputs">¿El paciente esta vivo?</strong>

              <v-radio-group v-model="patient.vivo">
                <v-radio
                  v-for="option in [
                    { label: 'Si', value: true },
                    { label: 'No', value: false },
                  ]"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                />
              </v-radio-group>
            </v-col>
            <v-col>
              <label for="muerte" class="label-inputs">
                Agregar fecha de muerte
              </label>
              <v-menu
                v-model="menu_death"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mt-3"
                    id="muerte"
                    label="Fecha de muerte"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    v-model="patient.death_d"
                    :disabled="patient.vivo"
                    outlined
                    :rules="[
                      (v) => patient.vivo || !!v || 'Falta fecha de muerte',
                    ]"
                  />
                </template>
                <v-date-picker
                  v-model="patient.death_d"
                  :max="maxDate"
                  min="1950-01-01"
                  @input="menu_death = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong class="label-inputs">Seleccionar región</strong>
              <v-select
                required
                :rules="[(v) => !!v || 'Falta comuna']"
                v-model="patient.region"
                :items="Object.keys(this.comunaRegiones).sort()"
                item-text="nombre"
                item-value="nombre"
                label="Region"
                return-object
                single-line
                outlined
              />
            </v-col>
            <v-col>
              <strong class="label-inputs">Seleccionar comuna</strong>
              <v-select
                required
                :rules="[(v) => !!v || 'Falta comuna']"
                v-model="patient.comuna"
                :items="
                  patient.region
                    ? Object.values(this.comuna(this.patient.region)).sort()
                    : ['Elija Region primero']
                "
                item-text="nombre"
                item-value="nombre"
                label="Comuna"
                return-object
                single-line
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="menu_ingreso_fa"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha de ingreso a la fundación"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    v-model="patient.fecha_ingreso_fa"
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="patient.fecha_ingreso_fa"
                  :max="maxDate"
                  min="1950-01-01"
                  @input="menu_ingreso_fa = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menu_enrol"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha de enrolamiento de la fundación"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    v-model="patient.enrol_d"
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="patient.enrol_d"
                  :max="maxDate"
                  min="1950-01-01"
                  @input="menu_enrol = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                class="modify-input"
                required
                :rules="[
                  (v) =>
                    (v !== null && v !== undefined) || 'Falta clinicaltrial',
                ]"
                :items="[
                  { value: 0, text: 'No Deriva' },
                  { value: 1, text: 'Deriva' },
                  { value: 2, text: 'Centro A' },
                  { value: 3, text: 'Centro B' },
                  { value: 4, text: 'Centro C' },
                ]"
                label="Paciente derivado desde otro centro"
                outlined
                v-model="patient.derivar"
              />
            </v-col>
            <v-col>
              <v-menu
                v-model="menu_derivacion"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha de derivacion"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    v-model="patient.date_derivacion"
                    :disabled="patient.derivar == 0"
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="patient.date_derivacion"
                  :max="maxDate"
                  min="1950-01-01"
                  @input="menu_derivacion = false"
                /> </v-menu
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                class="modify-input"
                required
                :rules="[(v) => !!v || 'Falta Sexo']"
                :items="['F', 'M']"
                label="Sexo"
                outlined
                v-model="patient.sex"
              />
            </v-col>
            <v-col>
              <v-select
                class="modify-input"
                required
                :rules="[(v) => !!v || 'Falta genero']"
                :items="['Cis', 'Trans', 'Otro']"
                label="Genero"
                outlined
                v-model="patient.genero"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                class="modify-input"
                required
                :rules="[(v) => !!v || 'Falta prevision']"
                :items="[
                  { text: 'Fonasa A', value: 'Fonasa A' },
                  { text: 'Fonasa B', value: 'Fonasa B' },
                  { text: 'Fonasa C', value: 'Fonasa C' },
                  { text: 'Fonasa D', value: 'Fonasa D' },
                  { text: 'Prais', value: 'Prais' },
                  { text: 'Prais/A', value: 'Prais/A' },
                  { text: 'Prais/B', value: 'Prais/B' },
                  { text: 'Prais/C', value: 'Prais/C' },
                  { text: 'Prais/D', value: 'Prais/D' },
                  { text: 'Dipreca', value: 'Dipreca' },
                  { text: 'Bloqueado', value: 'Bloqueado' },
                  { text: 'Isapre', value: 'Isapre' },
                  { text: 'SIN DATO', value: 'SIN DATO' },
                ]"
                label="Prevision"
                outlined
                v-model="patient.prevision"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                class="modify-input"
                label="Condición especial del paciente"
                v-model="patient.condicion_especial"
                :items="choices_condicion_especial"
                item-text="label"
                item-value="value"
                outlined
                no-data-text="No hay opciones disponibles aún"
              />
            </v-col>

            <v-col>
              <v-select
                class="modify-input"
                required
                :rules="[(v) => !!v || 'Falta clinicaltrial']"
                :items="[
                  { text: 'Sí', value: 1 },
                  { text: 'No', value: 2 },
                  { text: 'Desconocido', value: 9 },
                ]"
                label="¿Ha estado en algún ensayo clinico?"
                outlined
                v-model="patient.clinical_trial"
              />
            </v-col>
          </v-row>
          <v-row justify="end" class="ma-1">
            <v-col cols="auto">
              <v-btn type="submit" color="#F4A261" class="white--text px-10">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-card>

    <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
      <v-form @submit.prevent="sendData">
        <v-row>
          <v-col>
            <v-select
              class="modify-input"
              required
              :rules="[
                (v) => (v !== null && v !== undefined) || 'Falta clinicaltrial',
              ]"
              :items="[
                { value: 0, text: 'Homosexual' },
                { value: 1, text: 'Heterosexual' },
                { value: 2, text: 'Bisexual' },
                { value: 99, text: 'Desconocido' },
              ]"
              label="Orientación sexual"
              outlined
              v-model="patient.condusexual"
            />
          </v-col>
          <v-col>
            <v-select
              class="modify-input"
              required
              :rules="[(v) => !!v || 'Falta trabajador sexual']"
              :items="[
                { text: 'Si', value: 1 },
                { text: 'No', value: 2 },
                { text: 'Sin dato', value: 0 },
              ]"
              label="¿Trabajador sexual?"
              outlined
              v-model="patient.sexual_commerce"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="modify-input"
              required
              :rules="[(v) => !!v || 'Falta use of condom']"
              :items="[
                { text: 'Si', value: 1 },
                { text: 'No', value: 2 },
                { text: 'Sin dato', value: 0 },
              ]"
              label="Uso de condón"
              outlined
              v-model="patient.use_of_condom"
            />
          </v-col>
          <v-col>
            <v-select
              class="modify-input"
              required
              :rules="[(v) => !!v || 'Falta frecuencia uso de condón']"
              :items="[
                { text: 'Nunca', value: 1 },
                { text: 'Rara vez', value: 2 },
                { text: 'Mayoria de las veces', value: 3 },
                { text: 'Siempre', value: 4 },
                { text: 'Sin Datos', value: 0 },
              ]"
              label="Frecuencia de uso de condón"
              outlined
              v-model="patient.freq_use_of_condom"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="modify-input"
              required
              :rules="[(v) => !!v || 'Falta Nivel Educacional']"
              :items="[
                { value: 0, text: 'Analfabeto' },
                { value: 1, text: 'Educación Básica' },
                { value: 2, text: 'Educación Media' },
                { value: 3, text: 'Educación Técnica' },
                { value: 4, text: 'Universitaria' },
                { value: 9, text: 'Sin Dato' },
              ]"
              label="Nivel educacional completo más alto"
              outlined
              v-model="patient.nivel_educacional"
            />
          </v-col>
          <v-col>
            <v-select
              class="modify-input"
              required
              :rules="[(v) => !!v || 'Situación laboral']"
              :items="[
                { text: 'No tengo trabajo/cesante', value: 0 },
                { text: 'Trabajo con contrato', value: 1 },
                {
                  text: 'Trabajo no remunerado (Cuidado de familiares, dueña de casa)',
                  value: 2,
                },
                { text: 'Soy estudiante', value: 3 },
                { text: 'Trabajo como independiente con boletas', value: 4 },
                { text: 'Soy jubilado/pensionado', value: 5 },
                { text: 'Trabajo como independiente sin boletas', value: 6 },
                { text: 'Soy estudiante y trabajo', value: 7 },
                { text: 'Sin dato', value: 99 },
              ]"
              label="Situación laboral"
              outlined
              v-model="patient.employment_description"
            />
          </v-col>
        </v-row>
        <v-row justify="end" class="ma-1">
          <v-col cols="auto">
            <v-btn type="submit" color="#F4A261" class="white--text px-10">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
    <parejas-componente />
  </v-container>
</template>
<script>
import ParejasComponente from "./ParejasComponente.vue";
export default {
  components: {
    ParejasComponente,
  },
  name: "InfoPacienteComponente",
  data() {
    return {
      birth_date_menu: false,
      menu_ingreso_fa: false,
      menu_enrol: false,
      menu_derivacion: false,
      menu_death: false,
      patient: {},
      parejas: [],
      choices_condicion_especial: [
        { label: "No", value: 0 },
        { label: "Dipreca", value: 1 },
        { label: "PPL", value: 2 },
        { label: "Hospitalizado", value: 3 },
        { label: "Pediátrico", value: 4 },
        { label: "Protocolo", value: 5 },
        { label: "Embarazada", value: 6 },
      ],
      situation_choices: [
        { value: 0, label: "Activo" },
        { value: 1, label: "Traslado" },
        { value: 2, label: "Abandono" },
        { value: 3, label: "Fallecido" },
        { value: 4, label: "Falso(+)" },
        { value: 5, label: "Paciente Otro Centro" },
        { value: 6, label: "NoVIH" },
      ],
    };
  },
  computed: {
    edad() {
      return (
        new Date().getFullYear() - new Date(this.patient.birth_d).getFullYear()
      );
    },
    menuState() {
      return this.parejas.map(() => false);
    },
    comunaRegiones() {
      return this.$store.getters["datosVariables/comunaRegiones"];
    },
    maxDate() {
      return new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString();
    },
  },
  methods: {
    toggleMenu(index) {
      this.$set(this.menuState, index, !this.menuState[index]);
    },
    async sendData() {
      const valid = this.$refs.form.validate();
      if (!valid) return;
      await this.$store.dispatch("patients/updatePatient", this.patient);
    },

    comuna(comuna) {
      try {
        return Object.values(this.comunaRegiones[comuna]);
      } catch (error) {
        return "Otra";
      }
    },
  },
  watch: {
    "patient.vivo"(value) {
      if (value == true) {
        this.patient.death_d = null;
      }
    },
    "patient.derivar"(value) {
      if (value == 0) {
        this.patient.date_derivacion = null;
      }
    },
  },
  async created() {
    const codigoconasida = this.$route.params.id;
    const paciente = await this.$store.dispatch(
      "patients/loadDatosPersonales",
      {
        codigoconasida,
      }
    );

    this.patient = paciente;
  },
};
</script>
<style>
.contenedores-monitoreo {
  padding: 20px;
}
.label-inputs {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;

  color: #787878;
}
</style>
