var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-5"},[_c('v-col',[_c('v-row',{staticClass:"mx-1 titulo-seccion-monitoreo"},[_vm._v("Etapa CDC")]),_c('v-row',[_c('v-col',{staticClass:"subtitulo-seccion-monitoreo",attrs:{"cols":"4"}},[_vm._v(" Fecha de registro CDC ")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo",attrs:{"cols":"4"}},[_vm._v("Etapa CDC")]),_c('v-col')],1),(_vm.cdc_values.length > 0)?_c('v-container',{staticClass:"mt-3 overflow-auto elevation-1 rounded-lg",staticStyle:{"max-height":"400px"}},_vm._l((_vm.cdc_values),function(cdc_value,index){return _c('v-form',{key:index,ref:"form",refInFor:true,staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Fecha de diagnóstico","placeholder":"Fecha de diagnóstico","rules":[
                  (v) =>
                    (v !== null && v !== undefined && v !== '') ||
                    'Falta fecha de diagnóstico',
                ],"required":""},model:{value:(cdc_value.date),callback:function ($$v) {_vm.$set(cdc_value, "date", $$v)},expression:"cdc_value.date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menuState[index]),callback:function ($$v) {_vm.$set(_vm.menuState, index, $$v)},expression:"menuState[index]"}},[_c('v-date-picker',{attrs:{"max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10),"min":"1950-01-01"},on:{"input":function($event){_vm.menuState[index] = false}},model:{value:(cdc_value.date),callback:function ($$v) {_vm.$set(cdc_value, "date", $$v)},expression:"cdc_value.date"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"modify-input",attrs:{"required":"","rules":[
              (v) =>
                (v !== null && v !== undefined && v !== '') ||
                'Falta etapa de cdc',
            ],"items":[
              { value: 0, text: 'A' },
              { value: 1, text: 'A1' },
              { value: 2, text: 'A2' },
              { value: 3, text: 'A3' },
              { value: 4, text: 'B' },
              { value: 5, text: 'B1' },
              { value: 6, text: 'B2' },
              { value: 7, text: 'B3' },
              { value: 8, text: 'C' },
              { value: 9, text: 'C1' },
              { value: 10, text: 'C2' },
              { value: 11, text: 'C3' },
              { value: 99, text: 'Desconocido' },
            ],"label":"Etapa CDC","outlined":""},model:{value:(cdc_value.stage),callback:function ($$v) {_vm.$set(cdc_value, "stage", $$v)},expression:"cdc_value.stage"}})],1),_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"orange-button ma-5",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveEtapa(cdc_value, index)}}},[_vm._v(" Guardar ")]),_c('v-btn',{staticClass:"delete-button ma-5",on:{"click":function($event){$event.preventDefault();return _vm.deleteEtapa(cdc_value)}}},[_vm._v(" Borrar ")])],1)],1)}),1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"blue-button ma-5",attrs:{"disabled":_vm.new_form},on:{"click":_vm.addFormEtapa}},[_vm._v(" +Agregar Etapa ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }