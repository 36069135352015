<template>
  <v-container class="pa-5">
    <h1 class="titulo-seccion-monitoreo">Comorbilidades</h1>
    <v-row>
      <v-col class="subtitulo-seccion-monitoreo">Fecha diagnóstico</v-col>
      <v-col class="subtitulo-seccion-monitoreo">Categoria</v-col>
      <v-col class="subtitulo-seccion-monitoreo">Tipo específico</v-col>
      <v-col class="subtitulo-seccion-monitoreo justify-center">Acciones</v-col>
    </v-row>
    <v-container
      v-if="comorbilidades.length > 0"
      class="mt-3 overflow-auto elevation-1 rounded-lg"
      style="max-height: 400px"
    >
      <v-form
        v-for="(comorbilidad, index) in comorbilidades"
        :key="index"
        @submit.prevent
        ref="form"
        class="d-flex"
      >
        <v-col cols="3">
          <v-menu
            v-model="menuState[index]"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                label="Fecha de diagnóstico"
                placeholder="Fecha de diagnóstico"
                v-model="comorbilidad.date"
                :rules="[
                  (v) =>
                    (v !== null && v !== undefined && v !== '') ||
                    'Falta fecha de diagnóstico',
                ]"
                required
              />
            </template>
            <v-date-picker
              v-model="comorbilidad.date"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @input="menuState[index] = false"
            />
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-select
            class="modify-input"
            :items="[
              { value: 0, text: 'A' },
              { value: 1, text: 'B' },
              { value: 2, text: 'C' },
            ]"
            label="Categoria Enfermedad"
            outlined
            v-model="comorbilidad.categoria"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            class="modify-input"
            :rules="[
              (v) =>
                (v !== null && v !== undefined && v !== '') ||
                'Falta tipo específico',
            ]"
            :items="transform(opcionesEnfermedades, comorbilidad.categoria)"
            label="Tipo específico"
            outlined
            v-model="comorbilidad.comorbilidad"
          />
        </v-col>
        <v-col class="d-flex justify-center">
          <v-btn
            type="submit"
            class="orange-button ma-5"
            @click="addEnfermedad(comorbilidad, index)"
          >
            Guardar
          </v-btn>
          <v-btn
            class="delete-button ma-5"
            @click.prevent="deleteEnfermedad(comorbilidad)"
          >
            Borrar
          </v-btn>
        </v-col>
      </v-form>
    </v-container>
    <v-row class="d-flex justify-end">
      <v-btn
        class="blue-button ma-5"
        @click="addFormEnfermedad"
        :disabled="new_form"
      >
        +Agregar Enfermedad
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ComorbilidadesComponente",
  data() {
    return {
      comorbilidades: [],
      opcionesEnfermedades: [],
      new_form: false,
    };
  },
  methods: {
    async addEnfermedad(item, index) {
      const valid = this.$refs.form[index] && this.$refs.form[index].validate();
      if (!valid) return;
      const { id, comorbilidad, date } = item;
      if (id) {
        try {
          await this.$store.dispatch("patients/updateEnfermedadPaciente", {
            id,
            body: { comorbilidad, date },
          });
        } catch (error) {
          console.error("Error updating enfermedad:", error);
        }
      } else {
        const response = await this.$store.dispatch(
          "patients/addEnfermedadPaciente",
          { comorbilidad, date, paciente: this.rut }
        );
        if (response.status === 201) {
          item.id = response.data.id;
          this.new_form = false;
        }
      }
    },
    addFormEnfermedad() {
      this.comorbilidades.push({
        id: false,
        date: "",
        categoria: "",
        tipo_especifico: "",
      });
      this.new_form = true;
    },
    deleteEnfermedad(comoborbilidad) {
      const { id } = comoborbilidad;

      if (id) {
        this.$store.dispatch("patients/deleteEnfermedadPaciente", { id });
      } else {
        this.new_form = false;
      }
      this.comorbilidades = this.comorbilidades.filter(
        (comoborbilidad) => comoborbilidad.id !== id
      );
    },
    transform(lista, categoria = "") {
      if (lista) {
        if (categoria !== "") {
          lista = lista.filter((item) => item.categoria == categoria);
        }
        return lista.map((item) => {
          return {
            value: item.id,
            text: item.tipo_especifico,
          };
        });
      } else {
        return [];
      }
    },
  },
  computed: {
    menuState() {
      return this.comorbilidades.map(() => false);
    },
    rut() {
      return this.$store.getters["patients/getPatient"].rut;
    },
  },
  async created() {
    this.opcionesEnfermedades =
      this.$store.getters["patients/getOpcionesEnfermedades"];
    if (!this.opcionesEnfermedades) {
      await this.$store.dispatch("patients/getOpcionesEnfermedades");
      this.opcionesEnfermedades =
        this.$store.getters["patients/getOpcionesEnfermedades"];
    }
    try {
      const { id } = this.$route.params;
      const response = await this.$store.dispatch(
        "patients/getEnfermedadesPaciente",
        {
          codigoconasida: id,
          tipo_enfermedad: "comorbilidad",
        }
      );
      this.comorbilidades = response.data.results;

      if (this.comorbilidades.length === 0) {
        this.addFormEnfermedad();
      }
    } catch (error) {
      console.error("Error loading enfermedades:", error);
    }
  },
};
</script>
