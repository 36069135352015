import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      items_cdc: [
        "A1",
        "A2",
        "A3",
        "B",
        "B1",
        "B2",
        "B3",
        "C",
        "C1",
        "C2",
        "C3",
        "Desconocido"
      ],
      posologia_pac: [
        "1q6h",
        "1q8h",
        "2q8h",
        "1q12h",
        "2q12h",
        "0,5q24h",
        "1q24h",
        "1,5q264h",
        "2q24h",
        "3q24h",
        "4q24h",
        "5q24h",
        "1q48h",
        "1q72h",
        "1qS",
        "1,5qS",
        "2qS",
        "3qS",
        "6qS",
      ],
      PROTOCOLO_choices: [
        { mode: 1, descripcion: "Verdadero" },
        { mode: 2, descripcion: "Falso" },
      ],
      items_mode_2: [
        { mode: "1.0", descripcion: "Contacto Homosexual" },
        { mode: "2.0", descripcion: "Usuario de drogas inyectables" },
        {
          mode: "5.0",
          descripcion: "Transfusión",
        },
        { mode: "6.0", descripcion: "Contacto Heterosexual" },
        { mode: "8.0", descripcion: "Perinatal" },
        { mode: "10.0", descripcion: "Bisexual" },
        { mode: "90.0", descripcion: "Otro (Especificar en mode_oth)" },
        { mode: "99.0", descripcion: "Desconocido" },
      ],
      items_mode_oth: [
        { traduccion: "Bisexual", original: "Bisexual" },
        { traduccion: "Heterosexual", original: "Heterosexual" },
        {
          traduccion: "Contacto Heterosexual",
          original: "Heterosexual contact",
        },
        { traduccion: "Homo/Bisexual", original: "Homo/Bisexual" },
        { traduccion: "No responde", original: "No responde" },
        { traduccion: "Contacto Homosexual", original: "Homosexual" },
        { traduccion: "Sexual", original: "Sexual" },
      ],
      items_educacion: [
        "UNIVERSITARIA",
        "TECNICO",
        "MEDIA",
        "BASICA",
        "SIN DATO",
      ],
      items_laboral: ["SIN DATO", "ACTIVO", "PASIVO (estudiante que no trabaja y jubilados)", "CESANTE"],
      items_prevision: [
        "Fonasa A",
        "Fonasa B",
        "Fonasa C",
        "Fonasa D",
        "Prais",
        "Prais/A",
        "Prais/B",
        "Prais/D",
        "Dipreca",
        "Bloqueado",
        "Isapre",
      ],
      motivo_choices: [
        "SIMPLIFICACION",
        "NAIVE",
        "RAM",
        "STOCK",
        "ABANDONO",
        "TRASLADO",
        "EXTRANJERO",
        "FRACASO VIROLOGICO",
        "UPGRADE",
        "ISAPRE",
        "PROTOCOLO",
        "INTERACCION",
        "IR",
        "CONTRAINDICACION",
        "AJUSTE DOSIS",
        "TBC",
        "OTRO",
        "MALA ADHERENCIA",
        "EMBARAZO",
        "TBC TERMINO",
        "VHB",
        "REINICIO",
        "CONTINUACION",
        "CONTRAINDICACIÓN",
        "FRACASO INMUNOLOGICO",
        "HOSPITALIZACION",
        "Refuerzo mala adherencia",
      ],
      drogas: [
        "ABC",
        "AZT",
        "DTG",
        "DRV",
        "FTC",
        "LPV",
        "RAL",
        "TAF",
        "TDF",
        "3TC",
        "EVG",
        "RTV 100",
        "RPV",
        "EFV",
        "RAL HD",
        "ATV",
        "NVP",
        "FTC",
        "COBI",
      ],
      drogas_pop_choices: [
        "COTRIMOXAZOL FORTE",
        "ATORVASTATINA",
        "GEMFIBROZILO",
        "ATORVASTATINA",
        "ENSURE/NAT100",
        "OMEPRAZOL",
        "SERTRALINA",
        "FLUCONAZOL",
        "DAPSONA",
        "AZITROMICINA",
        "SULFATO FERROSO",
        "LOPERAMIDA",
        "CLORFENAMINA",
        "DOMPERIDONA",
        "ACICLOVIR",
        "FLUOXETINA",
        "LORATADINA",
        "AMITRIPTILINA",
        "ACIDO FOLICO",
        "CARBAMAZEPINA",
        "POLIVITAMINAS",
        "CEFADROXILO",
        "VALGANCICLOVIR",
        "RANITIDINA",
        "FENITOINA100",
        "LEVOFLOXACINO",
        "ACICLOVIR",
        "GANCICLOVIR",
        "ATORVASTATINA",
        "CLARITROMICINA",
        "ACIDO FOLICO",
        "CLARITRIMICINA",
        "FOLIFER",
        "AMOXICILINA",
        "ALBENDAZOL",
        "ATORVASTATINA",
        "DOXICICLINA",
      ],
      comunaRegiones: {
        "SIN DATO": ["SIN DATO"],
        "Arica y Parinacota": ["Arica", "Camarones", "Putre", "General Lagos"],
        "Tarapacá": ["Iquique", "Alto Hospicio", "Pozo Almonte", "Camiña", "Colchane", "Huara", "Pica"],
        "Antofagasta": ["Antofagasta", "Mejillones", "Sierra Gorda", "Taltal", "Calama", "Ollagüe", "San Pedro de Atacama", "Tocopilla", "María Elena"],
        "Atacama": ["Copiapó", "Caldera", "Tierra Amarilla", "Chañaral", "Diego de Almagro", "Vallenar", "Alto del Carmen", "Freirina", "Huasco"],
        "Coquimbo": ["La Serena", "Coquimbo", "Andacollo", "La Higuera", "Paiguano", "Vicuña", "Illapel", "Canela", "Los Vilos", "Salamanca", "Ovalle", "Combarbalá", "Monte Patria", "Punitaqui", "Río Hurtado"],
        "Valparaíso": ["Valparaíso", "Casablanca", "Concón", "Juan Fernández", "Puchuncaví", "Quintero", "Viña del Mar", "Isla de Pascua", "Los Andes", "Calle Larga", "Rinconada", "San Esteban", "La Ligua", "Cabildo", "Papudo", "Petorca", "Zapallar", "Quillota", "La Calera", "Hijuelas", "La Cruz", "Nogales", "San Antonio", "Algarrobo", "Cartagena", "El Quisco", "El Tabo", "Santo Domingo", "San Felipe", "Catemu", "Llaillay", "Panquehue", "Putaendo", "Santa María", "Quilpué", "Limache", "Olmué", "Villa Alemana"],
        "Libertador Gral. Bernardo O’Higgins": ["Rancagua", "Codegua", "Coinco", "Coltauco", "Doñihue", "Graneros", "Las Cabras", "Machalí", "Mostazal", "Olivar", "Peumo", "Pichidegua", "Quinta de Tilcoco", "Rengo", "Requínoa", "San Vicente", "Pichilemu", "La Estrella", "Litueche", "Marchigüe", "Navidad", "Paredones", "San Fernando", "Chépica", "Chimbarongo", "Lolol", "Nancagua", "Palmilla", "Peralillo", "Placilla", "Pumanque", "Santa Cruz"],
        "Maule": ["Talca", "Constitución", "Curepto", "Empedrado", "Maule", "Pelarco", "Pencahue", "Río Claro", "San Clemente", "San Rafael", "Cauquenes", "Chanco", "Pelluhue", "Curicó", "Hualañé", "Licantén", "Molina", "Rauco", "Romeral", "Sagrada Familia", "Teno", "Vichuquén", "Linares", "Colbún", "Longaví", "Parral", "Retiro", "San Javier", "Villa Alegre", "Yerbas Buenas"],
        "Ñuble": ["Chillán", "Cobquecura", "Coelemu", "Ninhue", "Portezuelo", "Quirihue", "Ránquil", "Treguaco", "Bulnes", "Chillán Viejo", "El Carmen", "Pemuco", "Pinto", "Quillón", "San Ignacio", "Yungay", "Coihueco", "Ñiquén", "San Carlos", "San Fabián", "San Nicolás"],
        "Biobío": ["Concepción", "Coronel", "Chiguayante", "Florida", "Hualqui", "Lota", "Penco", "San Pedro de la Paz", "Santa Juana", "Talcahuano", "Tomé", "Hualpén", "Lebu", "Arauco", "Cañete", "Contulmo", "Curanilahue", "Los Álamos", "Tirúa", "Los Ángeles", "Antuco", "Cabrero", "Laja", "Mulchén", "Nacimiento", "Negrete", "Quilaco", "Quilleco", "San Rosendo", "Santa Bárbara", "Tucapel", "Yumbel", "Alto Biobío"],
        "Araucanía": ["Temuco", "Carahue", "Cunco", "Curarrehue", "Freire", "Galvarino", "Gorbea", "Lautaro", "Loncoche", "Melipeuco", "Nueva Imperial", "Padre Las Casas", "Perquenco", "Pitrufquén", "Pucón", "Saavedra", "Teodoro Schmidt", "Toltén", "Vilcún", "Villarrica", "Cholchol", "Angol", "Collipulli", "Curacautín", "Ercilla", "Lonquimay", "Los Sauces", "Lumaco", "Purén", "Renaico", "Traiguén", "Victoria"],
        "Los Ríos": ["Valdivia", "Corral", "Lanco", "Los Lagos", "Máfil", "Mariquina", "Paillaco", "Panguipulli", "La Unión", "Futrono", "Lago Ranco", "Río Bueno"],
        "Los Lagos": ["Puerto Montt", "Calbuco", "Cochamó", "Fresia", "Frutillar", "Los Muermos", "Llanquihue", "Maullín", "Puerto Varas", "Castro", "Ancud", "Chonchi", "Curaco de Vélez", "Dalcahue", "Puqueldón", "Queilén", "Quellón", "Quemchi", "Quinchao", "Osorno", "Puerto Octay", "Purranque", "Puyehue", "Río Negro", "San Juan de la Costa", "San Pablo", "Chaitén", "Futaleufú", "Hualaihué", "Palena"],
        "Aisén del Gral. Carlos Ibáñez del Campo": ["Coihaique", "Lago Verde", "Aysén", "Cisnes", "Guaitecas", "Cochrane", "O'Higgins", "Tortel", "Chile Chico", "Río Ibáñez"],
        "Magallanes y de la Antártica Chilena": ["Punta Arenas", "Laguna Blanca", "Río Verde", "San Gregorio", "Cabo de Hornos (Ex Navarino)", "Antártica", "Porvenir", "Primavera", "Timaukel", "Natales", "Torres del Paine"],
        "Metropolitana de Santiago": ["Cerrillos", "Cerro Navia", "Conchalí", "El Bosque", "Estación Central", "Huechuraba", "Independencia", "La Cisterna", "La Florida", "La Granja", "La Pintana", "La Reina", "Las Condes", "Lo Barnechea", "Lo Espejo", "Lo Prado", "Macul", "Maipú", "Ñuñoa", "Pedro Aguirre Cerda", "Peñalolén", "Providencia", "Pudahuel", "Quilicura", "Quinta Normal", "Recoleta", "Renca", "Santiago", "San Joaquín", "San Miguel", "San Ramón", "Vitacura", "Puente Alto", "Pirque", "San José de Maipo", "Colina", "Lampa", "Tiltil", "San Bernardo", "Buin", "Calera de Tango", "Paine", "Melipilla", "Alhué", "Curacaví", "María Pinto", "San Pedro", "Talagante", "El Monte", "Isla de Maipo", "Padre Hurtado", "Peñaflor"]
      }
    };
  },
  actions,
  mutations,
  getters,
};
