export default {
  patients(state) {
    return state.patients;
  },
  patient(state) {
    return state.patient;
  },
  patientVariables(state) {
    if (state.patientVariables) {
      return state.patientVariables;
    } else {
      return "No hay datos de patientVariables";
    }
  },
  patientEncuesta(state) {
    if (state.patientEncuesta) {
      return state.patientEncuesta;
    } else {
      return "No hay datos de Encuesta";
    }
  },
  patientCD4(state) {
    if (state.patientCD4) {
      return state.patientCD4;
    } else {
      return false;
    }
  },
  patientCV(state) {
    if (state.patientCV) {
      return state.patientCV;
    } else {
      return false;
    }
  },
  pacienteIntervencional(state) {
    if (state.pacienteIntervencional) {
      return state.pacienteIntervencional;
    } else {
      return {}
    }
  },
  getPatientRNA(state) {
    return state.patientRNA || "No hay datos de RNA";
  },
  patientAuditorias(state) {
    return state.patientAuditorias;
  },
  patientTAR(state) {
    if (state.patientTAR) {
      return state.patientTAR;
    } else {
      return "No hay datos de TAR";
    }
  },
  patientPAC(state) {
    if (state.patientPAC) {
      return state.patientPAC;
    } else {
      return "No hay datos de PAC";
    }
  },
  patientPOP(state) {
    if (state.patientPOP) {
      return state.patientPOP;
    } else {
      return "No hay datos de POP";
    }
  },
  patientDrop(state) {
    if (state.patientDrop) {
      return state.patientDrop;
    } else {
      return "No hay datos de DROP";
    }
  },
  patientTerapiaPAC(state) {
    if (state.patientTerapiaPAC) {
      return state.patientTerapiaPAC
    } else {
      return "No hay datos patientTerapiaPAC"
    }
  },
  patientPredictions(state) {
    if (state.predictions) {
      return state.predictions
    } else {
      return "No hay datos predictions"
    }
  },
  tabStatus(state) {
    return state.tabStatus
  },
  statusForm(state) {
    return state.statusForm
  },
  getPatient(state) {
    return state.new_patient_data
  },
  isLoading(state) {
    return state.loading
  },
  getOpcionesEnfermedades(state) {
    return state.opcionesEnfermedades
  }


};
