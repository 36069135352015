var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"pa-3 my-5 rounded-lg",attrs:{"color":"#F0F8F8"}},[_c('h2',{staticClass:"titulo-seccion-monitoreo"},[_vm._v("Pareja")]),_c('v-row',[_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v(" Nº pareja")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v("Año del diagnostico")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v(" Estado serólogico del paciente ")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo justify-center"},[_vm._v("Acciones")])],1),(this.parejas.length > 0)?_c('v-container',{staticClass:"mt-3 overflow-auto elevation-1 rounded-lg",staticStyle:{"max-height":"400px"}},_vm._l((_vm.parejas),function(pareja,index){return _c('v-form',{key:index,ref:"form",refInFor:true,staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"modify-input",attrs:{"rules":[
            (v) =>
              /^[a-zA-ZÀ-ÿ\s0-9]+$/.test(v) ||
              'Solo se permiten letras, espacios y tildes',
          ],"outlined":"","placeholder":"Identificación"},model:{value:(pareja.identificacion),callback:function ($$v) {_vm.$set(pareja, "identificacion", $$v)},expression:"pareja.identificacion"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Fecha de diagnóstico","placeholder":"Fecha de diagnóstico","rules":[
                (v) =>
                  (v !== null && v !== undefined && v !== '') ||
                  'Falta fecha de diagnóstico',
              ],"required":""},model:{value:(pareja.fecha_diagnostico),callback:function ($$v) {_vm.$set(pareja, "fecha_diagnostico", $$v)},expression:"pareja.fecha_diagnostico"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menuState[index]),callback:function ($$v) {_vm.$set(_vm.menuState, index, $$v)},expression:"menuState[index]"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate,"min":"1950-01-01"},on:{"input":function($event){_vm.menuState[index] = false}},model:{value:(pareja.fecha_diagnostico),callback:function ($$v) {_vm.$set(pareja, "fecha_diagnostico", $$v)},expression:"pareja.fecha_diagnostico"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"modify-input",attrs:{"required":"","rules":[
            (v) => (v !== null && v !== undefined) || 'Falta clinicaltrial',
          ],"items":[
            { value: 99, text: 'Sin datos' },
            { value: 1, text: 'Pareja VIH (+)' },
            { value: 2, text: 'Pareja VIH (-)' },
            { value: 3, text: 'Desconocido' },
          ],"label":"Paciente derivado desde otro centro","outlined":""},model:{value:(pareja.estado_serologico),callback:function ($$v) {_vm.$set(pareja, "estado_serologico", $$v)},expression:"pareja.estado_serologico"}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"orange-button ma-5",attrs:{"type":"submit"},on:{"click":function($event){return _vm.savePareja(pareja, index)}}},[_vm._v(" Guardar ")]),_c('v-btn',{staticClass:"delete-button ma-5",on:{"click":function($event){$event.preventDefault();return _vm.deletePareja(pareja)}}},[_vm._v(" Borrar ")])],1)],1)}),1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"blue-button ma-5",attrs:{"disabled":_vm.new_form},on:{"click":_vm.addFormPareja}},[_vm._v(" +Agregar Pareja ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }