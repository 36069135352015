<template>
  <v-app>
    <v-container class="container-safe-area">
      <v-tabs
        fixed-tabs
        background-color="#1dc6d8"
        color="white"
        v-model="modelTab"
      >
        <v-tab
          :exact="true"
          :to="{
            name: 'Farmacia',
            params: { id: this.$route.params['id'] },
          }"
        >
          Farmacia
        </v-tab>
        <v-tab
          :exact="true"
          :to="{
            name: 'Ecosistema',
            params: { id: this.$route.params['id'] },
          }"
        >
          Ecosistema
        </v-tab>
        <v-tab
          :exact="true"
          :to="{
            name: 'Depresion',
            params: { id: this.$route.params['id'] },
          }"
        >
          Depresión
        </v-tab>
        <v-tab
          :exact="true"
          :to="{
            name: 'Alcohol',
            params: { id: this.$route.params['id'] },
          }"
        >
          Alcohol y otras drogas
        </v-tab>
      </v-tabs>
      <router-view />
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      modelTab: "encuesta",
      rut: null,
    };
  },
  methods: {
    async loadVariables() {
      const valores = await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
      this.rut = valores.rut;
    },
    async loadCD4() {
      await this.$store.dispatch("patients/loadCD4Data", {
        idPatient: this.rut,
      });
    },
    async loadRNA() {
      await this.$store.dispatch("patients/loadRNAData", {
        idPatient: this.rut,
      });
    },
    async loadAuditorias() {
      await this.$store.dispatch("patients/loadAuditorias", {
        idPatient: this.rut,
      });
    },
    async loadTAR() {
      await this.$store.dispatch("patients/loadTARlist", {
        idPatient: this.rut,
      });
    },
    async loadPAC() {
      await this.$store.dispatch("patients/loadPAC", {
        idPatient: this.rut,
      });
    },
    async loadPOP() {
      await this.$store.dispatch("patients/loadPOP", {
        idPatient: this.rut,
      });
    },
    async loadDrop() {
      await this.$store.dispatch("patients/loadDrop", {
        idPatient: this.loadVariables(),
      });
    },
  },
  async created() {
    await this.loadVariables().then(() => {
      this.loadDrop();
      this.loadCD4();
      this.loadAuditorias();
      this.loadTAR();
      this.loadPAC();
      this.loadPOP();
      this.loadRNA();
    });
  },
};
</script>
