var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"container-safe-area"},[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"#1dc6d8","color":"white"},model:{value:(_vm.modelTab),callback:function ($$v) {_vm.modelTab=$$v},expression:"modelTab"}},[_c('v-tab',{attrs:{"exact":true,"to":{
          name: 'pacienteFastTrackEncuesta',
          params: { id: this.id },
        }}},[_vm._v(" Encuesta ")]),_c('v-tab',{attrs:{"disabled":_vm.tabStatus.variables,"exact":true,"to":{
          name: 'pacienteFastTrackVariables',
          params: { id: this.id },
        }}},[_vm._v(" Variables ")]),_c('v-tab',{attrs:{"disabled":_vm.tabStatus.prediccion,"exact":true,"to":{
          name: 'pacienteFastTrackPrediction',
          params: { id: this.id },
        }}},[_vm._v(" Predicción ")]),_c('v-tab',{attrs:{"disabled":_vm.tabStatus.modelo,"exact":true,"to":{
          name: 'pacienteFastTrackIntervencional',
          params: { id: this.id },
        }}},[_vm._v(" Modelo Intervencional ")])],1)],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }