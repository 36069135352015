
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        terapias: [],
        terapias_pop: [],
        entregas: [],
        opcionesAccion: [
            { value: 0, text: "Sin datos" },
            { value: 1, text: "Cambio" },
            { value: 2, text: "Inicio" },
            { value: 3, text: "Continuacion" },
            { value: 4, text: "Reinicio" },
            { value: 5, text: "Reinicio/cambio" },
            { value: 6, text: "Continuacion/cambio" },
        ],
        opcionesMotivo: [
            { value: 0, text: "Simplificacion" },
            { value: 1, text: "Naive" },
            { value: 2, text: "Ram" },
            { value: 3, text: "Stock" },
            { value: 4, text: "Abandono" },
            { value: 5, text: "Traslado" },
            { value: 6, text: "Extranjero" },
            { value: 7, text: "Fracaso virologico" },
            { value: 8, text: "Upgrade" },
            { value: 9, text: "Isapre" },
            { value: 10, text: "Protocolo" },
            { value: 11, text: "Interaccion" },
            { value: 12, text: "Ir" },
            { value: 13, text: "Ajuste dosis" },
            { value: 14, text: "Tbc" },
            { value: 15, text: "Otro" },
            { value: 16, text: "Mala adherencia" },
            { value: 17, text: "Embarazo" },
            { value: 18, text: "Tbc termino" },
            { value: 19, text: "Vhb" },
            { value: 20, text: "Reinicio" },
            { value: 21, text: "Continuacion" },
            { value: 22, text: "Contraindicacion" },
            { value: 23, text: "Fracaso inmunologico" },
            { value: 24, text: "Hospitalizacion" },
            { value: 25, text: "Refuerzo mala adherencia" },
        ],
        opcionesArt_rs: [
            { "value": "1", "text": "Fallo de tratamiento" },
            { "value": "1.1", "text": "Fallo virológico" },
            { "value": "1.3", "text": "Fallo inmunológico – caída de CD4" },
            { "value": "1.4", "text": "Progresión clínica" },
            { "value": "1.5", "text": "Resistencia de la droga basado en el genotipo de VIH-1" },
            { "value": "2", "text": "Redistribución anormal de grasa" },
            { "value": "3", "text": "Relacionada a las enfermedades cardiovasculares" },
            { "value": "3.1", "text": "Dislipidemia" },
            { "value": "3.2", "text": "Enfermedades cardiovasculares" },
            { "value": "4", "text": "Reacción de hipersensibilidad (RHS) al sulfato de abacavir" },
            { "value": "5", "text": "Toxicidad – Predominantemente desde el abdomen / Tracto Gastrointestinal (GI)" },
            { "value": "5.1", "text": "Toxicidad – Tracto GI" },
            { "value": "5.2", "text": "Toxicidad – Hígado" },
            { "value": "5.3", "text": "Toxicidad – Páncreas" },
            { "value": "6", "text": "Toxicidad – Predominantemente desde el sistema nervioso" },
            { "value": "6.1", "text": "Toxicidad – Neuropatía Periférica" },
            { "value": "6.2", "text": "Toxicidad – Sistema Nervioso Central" },
            { "value": "7", "text": "Toxicidad – Predominantemente desde los riñones" },
            { "value": "8", "text": "Toxicidad – Predominantemente desde el sistema endocrino" },
            { "value": "8.1", "text": "Diabetes" },
            { "value": "9", "text": "Toxicidad Hematológica (anemia... etc)" },
            { "value": "10", "text": "Hiperlactatemia/acidosis láctica" },
            { "value": "11", "text": "Toxicidad – Dermatológica" },
            { "value": "88", "text": "Muerte" },
            { "value": "89", "text": "Pérdida del seguimiento" },
            { "value": "89.1", "text": "Transferido a otro centro" },
            { "value": "90", "text": "Efectos secundarios – cualquiera de los de arriba pero inespecíficos" },
            { "value": "90.1", "text": "Comorbilidad" },
            { "value": "90.2", "text": "Comorbilidad resuelta" },
            { "value": "91", "text": "Toxicidad, no mencionada arriba" },
            { "value": "92", "text": "Disponible para más tratamientos efectivos" },
            { "value": "92.1", "text": "Simplifica tratamientos disponibles" },
            { "value": "92.3", "text": "Interacción de droga" },
            { "value": "92.31", "text": "Interacción de droga - comenzando tratamiento de la tuberculosis" },
            { "value": "92.32", "text": "Interacción de droga - finalizando tratamiento de la tuberculosis" },
            { "value": "92.4", "text": "Cambio de Protocolo" },
            { "value": "92.5", "text": "Cambio de criterios de elegibilidad" },
            { "value": "92.6", "text": "Medicamento preferido ahora disponible" },
            { "value": "92.9", "text": "Cambio en el tratamiento no debido a efectos secundarios, fallas, baja adherencia o contraindicaciones" },
            { "value": "93", "text": "Interrupción estructurada del tratamiento" },
            { "value": "93.1", "text": "Interrupción estructurada del tratamiento (IET) – elevado CD4" },
            { "value": "94", "text": "Deseo del paciente/decisión, no especificada arriba" },
            { "value": "94.1", "text": "No-adherencia" },
            { "value": "95", "text": "Decisión del médico, no especificada anteriormente" },
            { "value": "96", "text": "Embarazo" },
            { "value": "96.1", "text": "Embarazo previsto o posible" },
            { "value": "96.2", "text": "Término del embarazo" },
            { "value": "96.3", "text": "Asesoramiento sobre preocupaciones preconceptuales" },
            { "value": "97.6", "text": "Droga no disponible" },
            { "value": "98", "text": "Otras causas, no especificadas arriba" },
            { "value": "99", "text": "Desconocido" },
        ]
    },
    mutations: {
        SET_TERAPIAS(state, terapias) {
            state.terapias = terapias
        },
        SET_TERAPIAS_POP(state, terapias_pop) {
            state.terapias_pop = terapias_pop
        },
        SET_ENTREGAS(state, entregas) {
            state.entregas = entregas
        },
        actualizarTerapia(state, terapiaActualizada) {
            const index = state.terapias.findIndex(t => t.id === terapiaActualizada.id);
            if (index !== -1) {
                state.terapias.splice(index, 1, terapiaActualizada);
            }
        },
        agregarTerapia(state, nuevaTerapia) {
            state.terapias.push(nuevaTerapia);
        },
        agregarTerapiaPop(state, nuevaTerapia) {
            state.terapias_pop.push(nuevaTerapia);
        },
        actualizarTerapiasPop(state, id) {
            state.terapias_pop = state.terapias_pop.filter(t => t.id !== id);
        },
        actualizarEntregas(state, id) {
            state.entregas = state.entregas.filter(t => t.uuid !== id);
        },
    },
    getters: {
        getAccionText: (state) => (value) => {
            const accion = state.opcionesAccion.find(option => option.value === value);
            return accion ? accion.text : 'Desconocido';
        },
        getMotivoText: (state) => (value) => {
            const motivo = state.opcionesMotivo.find(option => option.value === value);
            return motivo ? motivo.text : 'Desconocido';
        },
        terapiaIds: (state) => {
            return state.terapias.map(terapia => terapia.id);
        },
        getTerapias: (state) => {
            return state.terapias
        }
    },
    actions: {
        async fetchTerapias({ commit }, pacienteConasida) {
            try {
                // Asume que tienes una API en /api/terapias
                const response = await axios.get(`/terapia_pac/?codigoconasida=${pacienteConasida}`)
                commit('SET_TERAPIAS', response.data)
            } catch (error) {
                console.error('Error fetching terapias:', error)
                // Aquí podrías manejar el error, por ejemplo mostrando una notificación
            }
        },
        async eliminarTerapiaPac({ commit, state }, id) {
            try {
                const response = await axios.delete(`/terapia_pac/${id}/`);
                if (response.status === 204) {
                    const newState = state.terapias.filter(terapia => terapia.id !== id);
                    commit('SET_TERAPIAS', newState);
                    return true;
                }
            } catch (error) {
                console.error('Error al eliminar la terapia:', error);
                return error
            }
        },
        async fetchTerapiasPop({ commit }, pacienteConasida) {
            try {
                // Asume que tienes una API en /api/terapias
                const response = await axios.get(`/terapia_pop/?codigoconasida=${pacienteConasida}`)
                commit('SET_TERAPIAS_POP', response.data)
            } catch (error) {
                console.error('Error fetching terapias:', error)
                // Aquí podrías manejar el error, por ejemplo mostrando una notificación
            }
        },
        async fetchEntregas({ commit }, id) {
            try {
                // Asume que tienes una API en api/tar/list/
                const response = await axios.get(`api/tar/list/${id}`)

                commit('SET_ENTREGAS', response.data.results)
            } catch (error) {
                console.error('Error fetching entregas:', error)
                // Aquí podrías manejar el error, por ejemplo mostrando una notificación
            }
        },
        async actualizarTerapia({ commit }, terapia) {
            try {
                const terapiaResponse = await axios.put(`terapia_pac/${terapia.id}/`, terapia)
                const drogasPromises = terapia.drogas.map(droga => {
                    console.log(droga)
                    if (droga.id) {
                        return axios.put(`/droga_pac/${droga.id}/`, {
                            ...droga, terapia_pac: terapia.id
                        })
                    } else {
                        return axios.post("drogas_pac", { ...droga, terapia_pac: terapia.id })
                    }
                })
                await Promise.all(drogasPromises);
                commit('actualizarTerapia', terapiaResponse);
            } catch (error) {
                console.error('Error updating terapia:', error);
                throw error;
            }
        },
        async crearTerapia({ commit }, nuevaTerapia) {
            const { drogas } = nuevaTerapia
            console.log(nuevaTerapia);
            try {
                // Aquí harías la llamada a tu API para crear la nueva terapia
                const terapiaResponse = await axios.post(`terapia_pac/`, nuevaTerapia)
                const terapiaCreada = terapiaResponse.data
                const drogasPromises = drogas.map(droga =>
                    axios.post('droga_pac/', { ...droga, terapia_pac: terapiaCreada.id })
                )
                await Promise.all(drogasPromises)
                // Asumiendo que la API devuelve la nueva terapia creada
                commit('agregarTerapia', terapiaCreada);
            } catch (error) {
                console.error('Error al crear la terapia:', error);
                throw error;
            }
        },
        async crearTerapiaPop({ commit }, nuevaTerapia) {
            const { drogas } = nuevaTerapia
            console.log(nuevaTerapia);
            try {
                // Aquí harías la llamada a tu API para crear la nueva terapia
                const terapiaResponse = await axios.post(`terapia_pop/`, nuevaTerapia)
                const terapiaCreada = terapiaResponse.data
                const drogasPromises = drogas.map(droga =>
                    axios.post('droga_pop/', { ...droga, terapia_pop: terapiaCreada.id })
                )
                await Promise.all(drogasPromises)
                // Asumiendo que la API devuelve la nueva terapia creada
                commit('agregarTerapiaPop', terapiaCreada);
            } catch (error) {
                console.error('Error al crear la terapia:', error);
                throw error;
            }
        },
        /* eslint-disable-next-line no-unused-vars */
        async crearRetiro({ commit }, nuevoRetiro) {
            try {
                const response = await axios.post(`api/tar/create`, nuevoRetiro);
                return response.data;
            } catch (error) {
                console.error('Error al crear el retiro:', error);
                throw error;
            }
        },

        async eliminarTerapiaPop({ commit }, id) {
            try {
                // Aquí harías la llamada a tu API para crear la nueva terapia
                const terapiaResponse = await axios.delete("terapia_pop/" + id + "/")
                // Asumiendo que la API devuelve la nueva terapia creada
                console.log(terapiaResponse);
                commit('actualizarTerapiasPop', id);
            } catch (error) {
                console.error('Error al eliminar la terapia:', error);
                throw error;
            }
        },
        async eliminarEntrega({ commit }, id) {
            try {
                const response = await axios.delete(`/api/tar/delete/${id}/`);
                if (response.status === 204) {
                    console.log("El recurso fue eliminado correctamente.");
                    commit('actualizarEntregas', id);
                }
            } catch (error) {
                console.error("Error al eliminar el recurso:", error);
                throw error;
            }
        },

    },

}