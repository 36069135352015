<template>
  <v-app>
    <v-container class="container-safe-area">
      <section>
        <h1 style="font-size: 200%">Predicción Fast Track</h1>

        <div v-if="patientVariables.probability_ft">
          <h2>Clasificación de Adherencia</h2>
          <p style="font-size: 120%">
            {{ patientVariables.clasificacion_ft }}
          </p>
        </div>
        <div v-else-if="patientVariables.previous_tar_treatment">
          <p>
            El paciente ya tuvo tratamiento antiretroviral por lo que no se
            puede predecir la adherencia Fast Track
          </p>
        </div>
        <v-btn
          :to="{
            name: 'pacienteFastTrackVariables',
            params: { id: this.$route.params['id'] },
          }"
          >Atras</v-btn
        >
        <v-btn
          v-if="
            patientVariables.probability_ft ||
            patientVariables.previous_tar_treatment
          "
          :to="{
            name: 'pacienteFastTrackIntervencional',
            params: { id: this.$route.params['id'] },
          }"
        >
          Ir a modelo intervencional
        </v-btn>

        <v-btn v-else @click="predict" class="normal"> Predecir </v-btn>
        <v-divider class="sep"></v-divider>
        <v-btn
          block
          @click="showValues"
          v-if="patientVariables && patientEncuesta"
        >
          Valores de input para el predictor
        </v-btn>

        <ul v-if="showvalues">
          <li><b>Sexo: </b> {{ patientVariables.sex }}</li>
          <li><b>mode: </b> {{ patientVariables.mode }}</li>
          <li><b>mode_oth: </b> {{ patientVariables.mode_oth }}</li>
          <li><b>cdcstage: </b> {{ patientVariables.cdcstage_paciente }}</li>
          <li><b>aids_y: </b> {{ patientVariables.aids_y }}</li>
          <li><b>cd4_mean: </b> {{ patientVariables.cd4_first }}</li>
          <li><b>Derivar: </b> {{ patientVariables.Derivar }}</li>
          <li><b>Edad: </b> {{ patientVariables.edad }}</li>
          <li><b>Hla: </b> {{ patientVariables.hla }}</li>
          <li><b>Pop: </b> {{ patientVariables.pop_ingreso }}</li>
          <li>
            <b>condicion_especial: </b>
            {{ patientVariables.condicion_especial }}
          </li>
          <li><b>Prevision: </b> {{ patientVariables.Prevision }}</li>
          <li>
            <b>employment_description:</b>
            {{ patientVariables.employment_description }}
          </li>
          <li><b>ADQUISICION: </b> {{ patientVariables.ADQUISICION }}</li>
          <li><b>CONDUSEXUAL: </b> {{ patientVariables.CONDUSEXUAL }}</li>
          <li>
            <b>Fecha_ingreso_FA: </b> {{ patientVariables.Fecha_Ingreso_FA }}
          </li>
          <li>
            <b>Comuna Segmento: </b>{{ patientVariables.Comuna_Segmento }}
          </li>
          <li><b>Policlinico: </b>{{ patientVariables.Policlinico }}</li>
          <li>
            <b>Rp_Hospitalizado: </b>{{ patientVariables.rp_Hospitalizado }}
          </li>
          <li>
            <b>Segmento_etario: </b> {{ patientVariables.Segmento_etario }}
          </li>
          <li><b>Genero: </b> {{ patientVariables.Genero }}</li>
          <li>
            <b>Tiene_Autorizado: </b> {{ patientVariables.Tiene_Autorizado }}
          </li>
          <li>
            <b>nivel_educacional: </b> {{ patientVariables.nivel_educacional }}
          </li>
          <li><b>Encuesta_1:</b> {{ patientEncuesta.Encuesta_1 }}</li>
          <li><b>Encuesta_2:</b> {{ patientEncuesta.Encuesta_2 }}</li>
          <li><b>Encuesta_3:</b> {{ patientEncuesta.Encuesta_3 }}</li>
          <li><b>Encuesta_4:</b> {{ patientEncuesta.Encuesta_4 }}</li>
          <li><b>Encuesta_5:</b> {{ patientEncuesta.Encuesta_5 }}</li>
          <li><b>Encuesta_6:</b> {{ patientEncuesta.Encuesta_6 }}</li>
          <li><b>Encuesta_7:</b> {{ patientEncuesta.Encuesta_7 }}</li>
          <li><b>Encuesta_8:</b> {{ patientEncuesta.Encuesta_8 }}</li>
          <li><b>Encuesta_9:</b> {{ patientEncuesta.Encuesta_9 }}</li>
          <li><b>Encuesta_10:</b>{{ patientEncuesta.Encuesta_10 }}</li>
          <li><b>Encuesta_11:</b>{{ patientEncuesta.Encuesta_11 }}</li>
        </ul>
        <!------------------------------------BEGIN-Snackbar---------------------------------------------->
        <v-snackbar v-model="dialog" :color="snackbar_color">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="dialog = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
        <!------------------------------------END-Snackbar---------------------------------------------->
      </section>
    </v-container>
  </v-app>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(Vuex);
Vue.use(VueAxios, axios);
//Vue.axios.defaults.baseURL = 'https://api.vida.wic.cl'

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

export default {
  data() {
    return {
      snackbar_color: "success ",
      dialog: false,
      showvalues: false,
      message: null,
    };
  },
  methods: {
    showValues() {
      this.showvalues = !this.showvalues;
    },
    async predict() {
      const id = { rut: this.$store.getters["patients/patientVariables"].rut };

      await axios
        .post("paciente/api/fasttrack/", id)
        .then((result) => {
          this.$store.dispatch("patients/loadVariablesData", {
            idPatient:
              this.$store.getters["patients/patient"].data.codigoconasida,
          });
          this.$store.dispatch("patients/tabStatusAction", "modelo");
          return result.data;
        })
        .catch((error) => {
          this.dialog = true;
          this.snackbar_color = "error";
          this.message = error.response.data.message;
        });
    },
  },
  computed: {
    patientEncuesta() {
      return this.$store.getters["patients/patientEncuesta"];
    },
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
    patientCD4() {
      return this.$store.getters["patients/patientCD4"];
    },
  },
};
</script>
<style scoped>
.sep {
  margin: 15px;
}
</style>
