<template>
  <div>
    <nav-menu />
    <h1 v-if="authStatus === 'authenticating'">{{ authStatus }}</h1>
    <button @click.prevent="oninit" v-if="authStatus === 'authenticating'">
      auth
    </button>
    <router-view class="content-area" v-slot="slotProps" v-else>
      <component :is="slotProps.Component" />
    </router-view>
    <AppToast />
    <AppDialog />
    <footer-block />
  </div>
</template>

<script>
import FooterBlock from "./components/layouts/FooterBlock.vue";
import AppToast from "./components/AppToast.vue";
import AppDialog from "./components/AppDialog.vue";

export default {
  components: { FooterBlock, AppToast, AppDialog },
  data() {
    return {
      authStatus: false,
    };
  },
  methods: {
    async oninit() {
      await this.$store.dispatch("auth/checkAuthentication");
      const x = this.$store.getters["auth/currentState"];
      this.authStatus = x;
    },
  },
  created() {
    this.oninit();
  },
};
</script>

<style>
html,
body,
#app {
  font-family: "Roboto", sans-serif;
  background-color: #fbfbfb;
}
.container-safe-area {
  padding-top: 62px !important;
}
</style>
