<template>
  <v-container class="pa-5">
    <v-col>
      <v-row class="mx-1 titulo-seccion-monitoreo">Etapa CDC</v-row>
      <v-row>
        <v-col cols="4" class="subtitulo-seccion-monitoreo">
          Fecha de registro CDC
        </v-col>
        <v-col cols="4" class="subtitulo-seccion-monitoreo">Etapa CDC</v-col>
        <v-col></v-col>
      </v-row>
      <v-container
        v-if="cdc_values.length > 0"
        class="mt-3 overflow-auto elevation-1 rounded-lg"
        style="max-height: 400px"
      >
        <v-form
          v-for="(cdc_value, index) in cdc_values"
          :key="index"
          @submit.prevent
          ref="form"
          class="d-flex"
        >
          <v-col cols="4">
            <v-menu
              v-model="menuState[index]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  label="Fecha de diagnóstico"
                  placeholder="Fecha de diagnóstico"
                  v-model="cdc_value.date"
                  :rules="[
                    (v) =>
                      (v !== null && v !== undefined && v !== '') ||
                      'Falta fecha de diagnóstico',
                  ]"
                  required
                />
              </template>
              <v-date-picker
                v-model="cdc_value.date"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1950-01-01"
                @input="menuState[index] = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-select
              class="modify-input"
              required
              :rules="[
                (v) =>
                  (v !== null && v !== undefined && v !== '') ||
                  'Falta etapa de cdc',
              ]"
              :items="[
                { value: 0, text: 'A' },
                { value: 1, text: 'A1' },
                { value: 2, text: 'A2' },
                { value: 3, text: 'A3' },
                { value: 4, text: 'B' },
                { value: 5, text: 'B1' },
                { value: 6, text: 'B2' },
                { value: 7, text: 'B3' },
                { value: 8, text: 'C' },
                { value: 9, text: 'C1' },
                { value: 10, text: 'C2' },
                { value: 11, text: 'C3' },
                { value: 99, text: 'Desconocido' },
              ]"
              label="Etapa CDC"
              outlined
              v-model="cdc_value.stage"
            />
          </v-col>
          <v-col class="d-flex justify-center">
            <v-btn
              type="submit"
              class="orange-button ma-5"
              @click="saveEtapa(cdc_value, index)"
            >
              Guardar
            </v-btn>
            <v-btn
              class="delete-button ma-5"
              @click.prevent="deleteEtapa(cdc_value)"
            >
              Borrar
            </v-btn>
          </v-col>
        </v-form>
      </v-container>
      <v-row class="d-flex justify-end">
        <v-btn
          class="blue-button ma-5"
          @click="addFormEtapa"
          :disabled="new_form"
        >
          +Agregar Etapa
        </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "CDCComponente",
  data() {
    return {
      cdc_values: [],
      new_form: false,
    };
  },
  methods: {
    async saveEtapa(cdc_value, index) {
      const valid = this.$refs.form[index] && this.$refs.form[index].validate();

      if (!valid) return;
      const { id, date, stage } = cdc_value;

      if (id) {
        try {
          await this.$store.dispatch("patients/updateCDCStageData", {
            id,
            body: { date, stage },
          });
        } catch (error) {
          console.error("Error updating CDC stage data:", error);
        }
      } else {
        const { id: newId } = await this.$store.dispatch(
          "patients/addCDCStageData",
          {
            patient: this.patient.rut,
            date,
            stage,
          }
        );

        const existingIndex = this.cdc_values.findIndex(
          (p) => p.id === cdc_value.id
        );
        if (existingIndex !== -1) {
          this.$set(this.cdc_values, existingIndex, {
            ...cdc_value,
            id: newId,
          });
        } else {
          this.cdc_values.push({ ...cdc_value, id: newId });
        }
      }
      this.new_form = false;
    },
    deleteEtapa(cdc_value) {
      const { id } = cdc_value;
      if (id) {
        this.$store.dispatch("patients/deleteCDCStageData", { id });
      } else {
        this.new_form = false;
      }
      this.cdc_values = this.cdc_values.filter(
        (cdc_value) => cdc_value.id !== id
      );
    },
    addFormEtapa() {
      this.cdc_values.push({
        id: false,
        date: "",
        stage: "",
      });
      this.new_form = true;
    },
  },
  computed: {
    menuState() {
      return this.cdc_values.map(() => false);
    },
    patient() {
      return this.$store.getters["patients/getPatient"];
    },
  },
  async created() {
    try {
      const { id } = this.$route.params;
      const response = await this.$store.dispatch("patients/getCDCStageData", {
        codigoconasida: id,
      });
      this.cdc_values = response.data.results;
      if (this.cdc_values.length === 0) {
        this.addFormEtapa();
      }
    } catch (error) {
      console.error("Error fetching CDC stage data:", error);
    }
  },
};
</script>
