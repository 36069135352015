<template>
  <v-app>
    <v-container class="container-safe-area">
      <v-tabs
        fixed-tabs
        background-color="#1dc6d8"
        color="white"
        v-model="modelTab"
      >
        <v-tab
          :exact="true"
          :to="{
            name: 'pacienteFastTrackEncuesta',
            params: { id: this.id },
          }"
        >
          Encuesta
        </v-tab>
        <v-tab
          :disabled="tabStatus.variables"
          :exact="true"
          :to="{
            name: 'pacienteFastTrackVariables',
            params: { id: this.id },
          }"
        >
          Variables
        </v-tab>
        <v-tab
          :disabled="tabStatus.prediccion"
          :exact="true"
          :to="{
            name: 'pacienteFastTrackPrediction',
            params: { id: this.id },
          }"
        >
          Predicción
        </v-tab>
        <v-tab
          :disabled="tabStatus.modelo"
          :exact="true"
          :to="{
            name: 'pacienteFastTrackIntervencional',
            params: { id: this.id },
          }"
        >
          Modelo Intervencional
        </v-tab>
      </v-tabs>
    </v-container>
    <router-view />
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      disabledVariables: true,
      disabledPrediction: true,
      disabledModelo: true,
      modelTab: "encuesta",
      rut: null,
    };
  },
  computed: {
    tabStatus() {
      return this.$store.getters["patients/tabStatus"];
    },
  },
  methods: {
    enabledVariables() {
      this.disabledVariables = false;
    },
    async loadEncuesta() {
      await this.$store.dispatch("patients/loadEncuestaData", {
        idPatient: this.rut,
      });
    },
    async loadVariables() {
      const valores = await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
      this.rut = valores.rut;
    },
    async loadCD4() {
      await this.$store.dispatch("patients/loadCD4Data", {
        idPatient: this.rut,
      });
    },
    async loadIntervencional() {
      await this.$store.dispatch("patients/loadIntervencional", {
        idPatient: this.rut,
      });
    },
  },

  props: ["id"],
  async created() {
    await this.loadVariables()
      .then(() => {
        this.rut = this.$store.getters["patients/patientVariables"].rut; //Para cargar el rut
        if (this.$store.getters["patients/patientVariables"].probability_ft) {
          this.$store.dispatch("patients/tabStatusAction", "modelo");
        }
        if (
          this.$store.getters["patients/patientVariables"]
            .previous_tar_treatment
        ) {
          this.$store.dispatch("patients/tabStatusAction", "modelo");
        }
      })
      .then(() => {
        this.loadCD4(); //Para Cargar los cd4
      })
      .then(() => {
        this.loadIntervencional();
      })
      .then(() => {
        // Para habilitar o deshabilitar la pestaña de variables
        // Creo primero el objeto
        const obj = this.$store.getters["patients/patientVariables"];
        var subset = [
          "mode",
          "mode_oth",
          "cdcstage",
          "aids_y",
          "Genero",
          "sex",
          "ADQUISICION",
          "CONDUSEXUAL",
          "Derivar",
          "hla",
          "pop_ingreso",
          "birth_d",
          "edad",
          "condicion_especial",
          "Prevision",
          "employment_description",
          "nivel_educacional",
          "Fecha_Ingreso_FA",
          "Region",
          "Comuna",
          "Tiene_Autorizado",
          "Policlinico",
        ].reduce(function (obj2, key) {
          if (key in obj) obj2[key] = obj[key];
          return obj2;
        }, {});

        // Para agregar los valores de cd4 al arreglo anterior
        let cd4_values = null;
        if (this.$store.getters["patients/patientCD4"].length > 0) {
          cd4_values = this.$store.getters["patients/patientCD4"][0];
        } else {
          cd4_values = { cd4_v: null, cd4_d: null };
        }
        subset = {
          ...subset,
          cd4_v: cd4_values["cd4_v"],
          cd4_d: cd4_values["cd4_d"],
        };

        //Aca chequeo si hay algún nulo
        const isNullishVariables = Object.values(subset).some((value) => {
          if (value === null) {
            return true;
          }
          return false;
        });
        if (!isNullishVariables) {
          this.$store.dispatch("patients/tabStatusAction", "prediction");
        } else {
          this.$store.dispatch("patients/tabStatusActionTrue", "prediction");
        }
      })
      .then(() => this.loadIntervencional());

    //Cargo la encuesta y veo si hay algún nulo
    this.loadEncuesta().then(() => {
      const obj = this.$store.getters["patients/patientEncuesta"];
      const isNullishEncuesta = Object.values(obj).some((value) => {
        if (value === null) {
          return true;
        }
        return false;
      });
      if (!isNullishEncuesta) {
        this.$store.dispatch("patients/tabStatusAction", "variables");
      } else {
        this.$store.dispatch("patients/tabStatusActionTrue", "variables");
      }
    });
    //habilitar Modelo intervencional.
  },
};
</script>
