<template>
  <v-app>
    <v-container class="container-safe-area">
      <div>
        <h1 class="text-center">Pacientes</h1>
        <v-card>
          <v-card-title class="text-center">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :server-items-length="lenServer"
            :footer-props="footerProps"
            :page.sync="page"
            :headers="headers"
            :items="desserts"
            :items-per-page="itemsPerPage"
            :search="search"
            @pagination="changeSomewhere"
            @page-count="pageCount = $event"
            class="text-center"
            :loading="isLoading"
            loading-text="Cargando, por favor espere"
            no-data-text="No hay pacientes disponibles"
          >
            <template v-slot:[`item.codigoconasida`]="{ item }">
              <router-link
                v-if="permission === 'Medicos' || permission === 'Admin'"
                :to="{
                  name: 'paciente',
                  params: {
                    id: item.codigoconasida,
                  },
                }"
                >{{ item.codigoconasida }}</router-link
              >
              <p v-else>{{ item.codigoconasida }}</p>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-btn> Editar </v-btn>
              <v-btn @click="deletePatient(item.codigoconasida)" class="normal">
                Eliminar
              </v-btn>
            </template>

            <template v-slot:[`item.fasttrack`]="{ item }">
              <v-btn
                :to="{
                  name: 'pacienteFastTrack',
                  params: { id: item.codigoconasida },
                }"
              >
                FastTrack
              </v-btn>
              <v-btn
                :to="{
                  name: 'monitoreo',
                  params: { id: item.codigoconasida },
                }"
              >
                Datos generales
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :multi-line="multiLine"
          :color="snackbar_color"
        >
          <ul v-if="errores">
            <li v-for="(value, name) in errores" :key="value.id">
              {{ name }}: {{ value }}
            </li>
          </ul>
          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
export default {
  data() {
    return {
      snackbar: false,
      snackbar_color: null,
      multiLine: true,
      errores: [],
      admin: true,
      isLoading: true,
      lenServer: null,
      search: "",
      page: 1,
      pageCount: 0,
      desserts: [],
      footerProps: {
        itemsPerPageOptions: [10, 25, 50, -1],
        itemsPerPageText: "People",
        itemsPerPageAllText: "Everyone",
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      itemsPerPage: 15,
      headers: [
        {
          text: "Codigo Conasida",
          align: "start",
          value: "codigoconasida",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Apellidos",
          value: "apellido_paterno",
          sortable: false,
        },
        {
          text: "Nacimiento",
          value: "birth_d",
          sortable: false,
        },
        {
          text: "Derivacion",
          value: "unidad_derivacion",
          sortable: false,
        },
        { text: "Fecha Enrolamiento", value: "enrol_d" },
        /* { text: "Acción", value: "edit", align: "center" }, */
      ],
    };
  },
  computed: {
    permission() {
      return this.$store.getters["auth/autorizacion"];
    },
  },
  methods: {
    async changeSomewhere() {
      await axios
        .get("paciente/api/list?page=" + this.page)
        .then((response) => {
          return response;
        })
        .then((data) => {
          this.lenServer = data.data.count;
          this.desserts = data.data.results;
        })
        .catch((error) => {
          this.snackbar_color = "error";
          this.snackbar = true;
          this.errores = { Error: error.message };
          this.isLoading = false;
        });
    },
    async loadPatients() {
      try {
        await this.$store.dispatch("patients/loadPatients");
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Algo fue mal";
      }
    },
    deletePatient(codigoconasida) {
      const patients = this.$store.getters["patients/patients"].filter(
        (patient) => {
          return patient.codigoconasida !== codigoconasida;
        }
      );
      this.$store.dispatch("patients/deletePatient", {
        patients: patients,
        codigoconasida: codigoconasida,
      });
    },
  },
  created() {
    this.loadPatients();
  },
  mounted() {
    this.changeSomewhere();
    let admin = this.$store.getters["auth/autorizacion"];
    if (admin === "Medicos" || admin === "Admin") {
      this.headers.push({
        text: "Predictores",
        value: "fasttrack",
        align: "center",
      });
    }
  },
};
</script>
