var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-5"},[_c('h1',{staticClass:"titulo-seccion-monitoreo"},[_vm._v("Comorbilidades")]),_c('v-row',[_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v("Fecha diagnóstico")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v("Categoria")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v("Tipo específico")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo justify-center"},[_vm._v("Acciones")])],1),(_vm.comorbilidades.length > 0)?_c('v-container',{staticClass:"mt-3 overflow-auto elevation-1 rounded-lg",staticStyle:{"max-height":"400px"}},_vm._l((_vm.comorbilidades),function(comorbilidad,index){return _c('v-form',{key:index,ref:"form",refInFor:true,staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Fecha de diagnóstico","placeholder":"Fecha de diagnóstico","rules":[
                (v) =>
                  (v !== null && v !== undefined && v !== '') ||
                  'Falta fecha de diagnóstico',
              ],"required":""},model:{value:(comorbilidad.date),callback:function ($$v) {_vm.$set(comorbilidad, "date", $$v)},expression:"comorbilidad.date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menuState[index]),callback:function ($$v) {_vm.$set(_vm.menuState, index, $$v)},expression:"menuState[index]"}},[_c('v-date-picker',{attrs:{"max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),"min":"1950-01-01"},on:{"input":function($event){_vm.menuState[index] = false}},model:{value:(comorbilidad.date),callback:function ($$v) {_vm.$set(comorbilidad, "date", $$v)},expression:"comorbilidad.date"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"modify-input",attrs:{"items":[
            { value: 0, text: 'A' },
            { value: 1, text: 'B' },
            { value: 2, text: 'C' },
          ],"label":"Categoria Enfermedad","outlined":""},model:{value:(comorbilidad.categoria),callback:function ($$v) {_vm.$set(comorbilidad, "categoria", $$v)},expression:"comorbilidad.categoria"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"modify-input",attrs:{"rules":[
            (v) =>
              (v !== null && v !== undefined && v !== '') ||
              'Falta tipo específico',
          ],"items":_vm.transform(_vm.opcionesEnfermedades, comorbilidad.categoria),"label":"Tipo específico","outlined":""},model:{value:(comorbilidad.comorbilidad),callback:function ($$v) {_vm.$set(comorbilidad, "comorbilidad", $$v)},expression:"comorbilidad.comorbilidad"}})],1),_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"orange-button ma-5",attrs:{"type":"submit"},on:{"click":function($event){return _vm.addEnfermedad(comorbilidad, index)}}},[_vm._v(" Guardar ")]),_c('v-btn',{staticClass:"delete-button ma-5",on:{"click":function($event){$event.preventDefault();return _vm.deleteEnfermedad(comorbilidad)}}},[_vm._v(" Borrar ")])],1)],1)}),1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"blue-button ma-5",attrs:{"disabled":_vm.new_form},on:{"click":_vm.addFormEnfermedad}},[_vm._v(" +Agregar Enfermedad ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }