<template>
  <v-container>
    <h1 class="titulos">Monitoreo</h1>
    <v-container class="info-patient d-flex justify-space-between">
      <v-card v-for="(info, index) in nombresPatient" :key="index" flat>
        <span class="info-label">{{ info.label }} </span>
        <span class="info-value">{{
          info.value ? info.value : "No hay info"
        }}</span>
      </v-card>
    </v-container>
    <v-tabs v-model="tab" slider-color="#1dc6d8" grow>
      <v-tab
        v-for="item in items"
        :key="item.tab"
        class="tabs"
        active-class="tab-activa"
        color="red"
      >
        <v-icon>{{ item.icono }}</v-icon>
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <component v-bind:is="item.content" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import InfoPacienteComponente from "./components/InfoPacienteComponente.vue";
import DiagnosticoComponente from "./components/DiagnosticoComponente.vue";
import EncuestasComponente from "./components/EncuestasComponente.vue";
import TratamientoComponente from "./components/TratamientoComponente.vue";
import LaboratorioComponente from "./components/LaboratorioComponente.vue";

export default {
  components: {
    InfoPacienteComponente,
    DiagnosticoComponente,
    LaboratorioComponente,
    EncuestasComponente,
    TratamientoComponente,
  },
  data() {
    return {
      tab: 0,
      items: [
        {
          tab: "Información Paciente",
          content: "InfoPacienteComponente",
        },
        { tab: "Diagnóstico", content: "DiagnosticoComponente" },
        {
          tab: "Tratamiento y Medicamentos",
          content: "TratamientoComponente",
          icono: "mdi-pill-multiple",
        },
        { tab: "Laboratorio", content: "LaboratorioComponente" },
        { tab: "Encuestas", content: "EncuestasComponente" },
      ],
      nombresPatient: [
        { label: "Codigo CONASIDA", value: null },
        { label: "Nombre paciente", value: null },
        { label: "Rut / Nº Provisorio", value: null },
      ],
    };
  },
  async created() {
    let patient = this.$store.getters["patients/getPatient"];
    if (!patient) {
      patient = await this.$store.dispatch("patients/loadDatosPersonales", {
        codigoconasida: this.$route.params["id"],
      });
    }
    this.$store.dispatch("patients/getOpcionesEnfermedades");
    const nombre = `${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`;
    this.nombresPatient = [
      { label: "Codigo CONASIDA", value: patient.codigoconasida },
      { label: "Nombre paciente", value: nombre },
      { label: "Rut / Nº Provisorio", value: patient.rut },
    ];
  },
};
</script>
<style>
.tabs {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #a5a5a540;
}
.tab-activa {
  color: white !important;
  background-color: #1dc6d8;
}
.info-patient {
  background-color: #ffffff;
  margin: 15px 0px;
  padding: 20px 30px;
}
.info-label {
  font-size: 16px;
  font-weight: 400;
  color: #9c9c9c;
  margin-right: 10px;
}
.info-value {
  font-size: 16px;
  font-weight: 500;
  color: #474747;
}
</style>
