import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

export default {
    namespaced: true,
    state() {
        return {
            patient: null,
            patientEncuesta: null,
            patientEncuestaInfo: null,
            patientEncuestaLabo: null,
            patientEncuestaTrat: null,
            patientEncuestaTras: null,
            patientEncuestaDrugs: null,
            patientEncuestaObs: null,
            patientVariables: null,
            patientCD4: null,
            patientCV: null,
            patientAuditorias: null,
            patientTAR: null,
            patientPAC: null,
            patientPOP: null,
            patientRNA: [],
            pacienteIntervencional: null,
            patientDrop: null,
            patientTerapiaPAC: null,
            patients: [],
            predictions: null,
            tabStatus: { 'variables': true, 'prediccion': true, 'modelo': true },
            statusForm: null,
            new_patient_data: null,
            loading: false,
            parejas: [],
            patientCDCStage: [],
            opcionesEnfermedades: null
        }
    },
    actions,
    mutations,
    getters,
}