<template>
  <v-app>
    <v-container class="container-safe-area">
      <v-row>
        <v-col>
          <v-bottom-navigation color="primary" horizontal grow>
            <v-btn
              :exact="true"
              :to="{
                name: 'paciente',
                params: { id: this.id },
              }"
            >
              <span> Paciente {{ id }} </span>
              <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-btn
              :to="{
                name: 'pacienteFastTrack',
                params: { id: this.id },
              }"
            >
              <span> Fast Track</span>
              <v-icon>mdi-clock-fast</v-icon>
            </v-btn>
            <v-btn
              :exact="true"
              :to="{
                name: 'subPredictores',
                params: { id: this.id },
              }"
            >
              <span> Predictores</span>
              <v-icon>mdi-charity</v-icon>
            </v-btn>

            <v-btn
              :exact="true"
              :to="{
                name: 'monitoreo',
                params: { id: this.id },
              }"
            >
              Monitoreo
            </v-btn>
          </v-bottom-navigation>

          <router-view v-if="this.$route['name'] != 'paciente'"></router-view>
          <perfil-paciente v-else :id="id"></perfil-paciente>
        </v-col>
      </v-row>
      <v-row v-if="this.$route['name'] === 'paciente'" class="justify-center">
        <v-col v-if="this.predictions.length > 0">
          <v-card class="mt-5 px-5">
            <v-row>
              <v-col>
                <v-card-text>
                  <h1>Nivel de adherencia anual</h1>
                </v-card-text>
              </v-col>
            </v-row>

            {{ predictionFarmaciaData.valores }}

            <v-row>
              <v-col cols="12" md="8">
                <ApexChart
                  height="300"
                  width="100%"
                  type="line"
                  :options="options"
                  :series="series"
                ></ApexChart>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-else>
          <v-row>
            <v-col>
              <v-card-text>
                <h1>No hay datos de adherencia para este paciente</h1>
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import perfilPaciente from "./perfilPaciente.vue";
export default {
  components: { perfilPaciente },
  props: ["id"],
  data() {
    return {
      predictions: [],
      predictionEcosistemaData: null,
      predictionFarmaciaData: null,
      predictionDepresiónData: null,
      predictionAyDData: null,
      itemsVariables: [
        "Farmacia",
        "Ecosistema",
        "Depresión",
        "Alcohol y otras drogas",
        "Todas las variables",
      ],

      variableDefault: "Farmacia",

      options: {
        chart: {
          id: "vuechart-example",
          stacked: false,
        },
        markers: {
          size: 5,
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 5,
        },
        xaxis: {
          categories: [],
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.1,
            opacityFrom: 0.1,
            opacityTo: 0.9,
            stops: [0, 0, 100],
          },
        },
      },
      series: [
        {
          name: "Farmacia",
          type: "line",
          data: null,
        },
        {
          name: "Ecosistema",
          type: "line",
          data: null,
        },
        {
          name: "Depresión",
          type: "line",
          data: null,
        },
        {
          name: "AyD",
          type: "line",
          data: null,
        },
      ],
    };
  },
  methods: {
    //Predictions
    predictionEcosistema() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "Ecosistema";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      let result = predicciones.map((a) => Object.keys(a.label)[0]);
      return result;
    },

    predictionFarmacia() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "Farmacia";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      let result = predicciones.map((a) => Object.keys(a.label)[0]);
      return result;
    },
    //AyD
    predictionAyD() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "AyD";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      let result = predicciones.map((a) => Object.keys(a.label)[0]);
      return result;
    },
    //AyD
    predictionDepresion() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "Depresión";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      let result = predicciones.map((a) => Object.keys(a.label)[0]);
      return result;
    },
    years() {
      const value = this.$store.getters["patients/patientPredictions"];
      let predicciones = value;
      predicciones = predicciones.filter((el) => {
        return el.predictor == "Depresión";
      });
      predicciones.sort(function (a, b) {
        return a.year - b.year;
      });
      let result = predicciones.map((a) => a.year);
      return result;
    },

    async loadPredictions() {
      await this.$store.dispatch("patients/loadPredictions", this.rut);
    },
    loadPatient() {
      this.$store.dispatch("patients/loadDatosPersonales", {
        codigoconasida: this.$route.params["id"],
      });
    },
    async loadVariables() {
      const valores = await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
      this.rut = valores.rut;
    },
  },
  async created() {
    const { id: codigoconasida } = this.$route.params.id;
    await this.$store.dispatch("patients/loadDatosPersonales", {
      codigoconasida,
    });
    this.loadPatient();
    await this.loadVariables().then(() => {
      this.loadPredictions().then(() => {
        this.predictionEcosistemaData = this.predictionEcosistema();
        this.predictionFarmaciaData = this.predictionFarmacia();
        this.predictionDepresiónData = this.predictionDepresion();
        this.predictionAyDData = this.predictionAyD();
        this.series[0].data = this.predictionEcosistema();
        this.series[1].data = this.predictionFarmacia();
        this.series[2].data = this.predictionDepresion();
        this.series[3].data = this.predictionAyD();
        for (const element of this.predictionEcosistemaData) {
          this.predictions.push(element);
        }
        for (const element of this.predictionFarmaciaData) {
          this.predictions.push(element);
        }
        for (const element of this.predictionDepresiónData) {
          this.predictions.push(element);
        }
        for (const element of this.predictionAyDData) {
          this.predictions.push(element);
        }
        this.options.xaxis.categories = this.years();
      });
    });
  },
};
</script>
