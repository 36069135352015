export default {
    registerNewPatient(state, payload) {
        state.patients.push(payload)
    },
    setPatients(state, payload) {
        state.patients = payload
    },
    setPatient(state, payload) {
        state.patient = payload
    },
    addEncuestaFast(state, payload) {
        state.patient = { ...state.patient, "encuesta": payload }
    },
    setEncuestaPatient(state, payload) {
        state.patientEncuesta = payload
    },
    setEncuestaInfoPatient(state, payload) {
        state.patientEncuestaInfo = payload
    },
    setEncuestaLaboPatient(state, payload) {
        state.patientEncuestaLabo = payload
    },
    setEncuestaTratPatient(state, payload) {
        state.patientEncuestaTrat = payload
    },
    setEncuestaTrasPatient(state, payload) {
        state.patientEncuestaTras = payload
    },
    setEncuestaDrugsPatient(state, payload) {
        state.patientEncuestaDrugs = payload
    },
    setEncuestaObsPatient(state, payload) {
        state.patientEncuestaObs = payload
    },
    setVariable(state, payload) {
        state.patientVariables = payload
    },
    setCD4(state, payload) {
        state.patientCD4 = payload
    },
    setCV(state, payload) {
        state.patientCV = payload
    },
    SET_RNA(state, payload) {
        state.patientRNA = payload
    },
    setAuditorias(state, payload) {
        state.patientAuditorias = payload
    },
    setPacienteIntervencional(state, payload) {
        state.pacienteIntervencional = payload
    },
    setTAR(state, payload) {
        state.patientTAR = payload
    },
    setPAC(state, payload) {
        state.patientPAC = payload
    },
    setPOP(state, payload) {
        state.patientPOP = payload
    },
    setDrop(state, payload) {
        state.patientDrop = payload
    },
    setTerapiaPAC(state, payload) {
        state.patientTerapiaPAC = payload
    },
    setPredictions(state, payload) {
        state.predictions = payload
    },
    setTabVariables(state, payload) {
        state.tabStatus.variables = payload
    },
    setTabPrediction(state, payload) {
        state.tabStatus.prediccion = payload
    },
    setTabModelo(state, payload) {
        state.tabStatus.modelo = payload
    },
    setStatusForm(state, payload) {
        state.statusForm = payload
    },
    setNewPatientData(state, payload) {
        state.new_patient_data = payload
    },
    /* -----------------------------PAREJAS----------------------------- */
    setParejas(state, payload) {
        state.parejas = payload
    },
    updatePareja(state, payload) {
        state.parejas = state.parejas.map(pareja => {
            if (pareja.id === payload.id) {
                return payload
            }
            return pareja
        })
    },
    addPareja(state, payload) {
        state.parejas.push(payload)
    },
    deletePareja(state, payload) {
        state.parejas = state.parejas.filter(pareja => pareja.id !== payload)
    },
    /* ----------------------------- END PAREJAS---------------------- */
    setLoading(state, status) {
        state.loading = status
    },
    setCDCStage(state, payload) {
        state.patientCDCStage = payload
    },
    addCDCStage(state, payload) {
        state.patientCDCStage = [...state.patientCDCStage, payload]
    },
    updateCDCStage(state, payload) {
        state.patientCDCStage = state.patientCDCStage.map(stage => {
            if (stage.id === payload.id) {
                return payload
            }
            return stage
        })
    },
    removeCDCStage(state, payload) {
        state.patientCDCStage = state.patientCDCStage.filter(stage => stage.id !== payload)
    },
    setOpcionesEnfermedades(state, payload) {
        state.opcionesEnfermedades = payload
    }
}