import axios from "axios";
export default {
  async getOpcionesEnfermedades(context) {
    const response = await axios.get('enfermedad/')
    context.commit('setOpcionesEnfermedades', response.data)
  },
  async getEnfermedadesPaciente(_, { codigoconasida, tipo_enfermedad }) {
    const url = `enfermedad-paciente/?paciente__codigoconasida=${codigoconasida}&tipo_enfermedad=${tipo_enfermedad}`
    const response = await axios.get(url)
    if (response.status === 200) {
      return response
    } else {
      throw new Error('Error al obtener las enfermedades del paciente')
    }
  },
  async deleteEnfermedadPaciente({ dispatch }, { id }) {
    try {
      await axios.delete(`enfermedad-paciente/${id}`)
      dispatch('toast/showToast', { message: 'Enfermedad eliminada correctamente', color: 'success' }, { root: true })
    } catch (error) {
      console.log("Error al eliminar Enfermedad Paciente", error)
    }
  },
  async addEnfermedadPaciente({ dispatch }, payload) {
    const response = await axios.post('enfermedad-paciente/', payload)
    if (response.status == 201) {
      dispatch('toast/showToast', { message: 'Enfermedad agregada correctamente', color: 'success' }, { root: true })
      return response
    } else {
      dispatch('toast/showToast', { message: 'Error al agregar enfermedad', color: 'error' }, { root: true })
      return response
    }
  },
  async updateEnfermedadPaciente({ dispatch }, { id, body }) {
    const response = await axios.patch(`enfermedad-paciente/${id}/`, body)
    if (response.status === 200) {
      dispatch('toast/showToast', { message: 'Enfermedad actualizada correctamente', color: 'success' }, { root: true })
    } else {
      dispatch('toast/showToast', { message: 'Error al actualizar enfermedad', color: 'error' }, { root: true })
    }
  },
  async getCDCStageData(context, payload) {
    const { codigoconasida } = payload
    const response = await axios.get("cdc_stage/?patient__codigoconasida=" + codigoconasida);
    if (response.status === 200) {
      context.commit('setCDCStage', response.data.results)
      return response
    }
  },
  async updateCDCStageData({ commit, dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = await axios.patch(`cdc_stage/${id}/`, body)
      if (response.status === 200) {
        commit('updateCDCStage', response.data)
        dispatch('toast/showToast', { message: 'CDC Stage editado correctamente', color: 'success' }, { root: true })
        return response.data
      }
    } catch (error) {
      console.log("Error al actualizar el CDC Stage:", error)
      throw error
    }
  },
  async addCDCStageData({ commit, dispatch }, payload) {
    try {
      const response = await axios.post('cdc_stage/', payload)
      if (response.status === 201) {
        commit('addCDCStage', response.data)
        dispatch('toast/showToast', { message: 'CDC Stage agregado correctamente', color: 'success' }, { root: true })
        return response.data
      }
    } catch (error) {
      console.log("Error al agregar CDC Stage:", error)
      throw error
    }
  },
  async deleteCDCStageData({ dispatch, commit }, { id }) {
    try {
      await axios.delete(`cdc_stage/${id}/`)
      dispatch('toast/showToast', { message: 'CDC Stage eliminado correctamente', color: 'success' }, { root: true })
      commit('removeCDCStage', id)
    } catch (error) {
      console.log("Error al eliminar CDC Stage:", error)
    }
  },
  async addPatient(context, dataFirst) {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const data = dataFirst.dataPatient;
    var res = data.nacimiento.replace(/-/g, "").slice(2, 8);
    const codigoconasida =
      data.nombres[0] +
      data.apellido_paterno[0] +
      data.apellido_materno[0] +
      res.slice(4, 6) +
      res.slice(2, 4) +
      res.slice(0, 2) +
      data.rut.replace(/-/g, "").slice(-4);

    const dataPatient = {
      nombre: data.nombres,
      apellido_paterno: data.apellido_paterno,
      apellido_materno: data.apellido_materno,
      rut: data.rut,
      birth_d: data.nacimiento,
      date_derivacion: data.date_derivacion,
      unidad_derivacion: data.unidad_derivacion,
      codigoconasida: codigoconasida,
      nacionalidad: data.nacionalidad,
      otra_nacionalidad: data.otra_nacionalidad ? data.otra_nacionalidad : data.nacionalidad,
      previous_tar_treatment: data.previous_tar_treatment,
      register_date: currentDate,
      diagnostico_vih: data.diagnostico_vih,
      n_opo_inicial: data.n_opo_inicial,
      opo_inicial: data.opo_inicial,
    };

    return axios
      .post("/paciente/api/create", dataPatient)
      .then((response) => {
        if (response.status == 201) {
          console.log("Paciente agregado correctamente. Esta fue la data enviada: ", dataPatient);
          return response;
        }
      })
      .catch((error) => {
        if (!error["status"]) {
          error["status"] = 400;
        }
        return error;
      });
  },
  //***************************************************************************************************//
  async loadPatients(context /* payload */) {
    const response = await axios.get("paciente/api/list");
    const responseData = response.data.results;
    const patients = [];
    for (const key in responseData) {
      const patient = {
        codigoconasida: responseData[key].codigoconasida,
        nombres: responseData[key].nombre,
        primer_apellido: responseData[key].apellido_paterno,
        segundo_apellido: responseData[key].apellido_materno,
        rut: responseData[key].rut,
        nacimiento: responseData[key].birth_d,
        /*date_derivacion: responseData[key].dataPatient.date_derivacion,
        unidad_derivacion: responseData[key].dataPatient.unidad_derivacion, */
      };
      patients.push(patient);
    }
    context.commit("setPatients", patients);
  },
  //***************************************************************************************************//
  async loadEncuestaData(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/encuesta/detail/" + idPatient)
      .then((result) => {
        context.commit("setEncuestaPatient", result.data);
        return result.data;
      })
      .catch((error) => console.log(error));
  },
  async loadDatosPersonales(context, payload) {
    const { codigoconasida } = payload
    const response = await axios.get(`new_paciente/?codigoconasida=${codigoconasida}`).then((result) => {
      const { data } = result
      context.commit('setNewPatientData', data[0])
      return data[0]
    })
    return response
  },

  /*-------------------------- PAREJAS  --------------------------*/
  async loadParejas(context, payload) {
    const { codigoconasida } = payload
    const response = await axios.get(`pareja/?paciente__codigoconasida=${codigoconasida}`)
    if (response.status === 200) {
      context.commit('setParejas', response.data.results)
      return response
    }
  },
  async addPareja({ commit, dispatch }, payload) {
    try {
      const response = await axios.post('pareja/', payload)
      if (response.status === 201) {
        commit('addPareja', response.data)
        dispatch('toast/showToast', { message: 'Pareja agregada correctamente', color: 'success' }, { root: true })
        return response.data
      }
    } catch (error) {
      console.log("Error al agregar pareja:", error)
      throw error
    }
  },
  async updatePareja({ commit, dispatch }, payload) {
    const { id, body } = payload
    try {
      const response = await axios.patch(`pareja/${id}/`, body)
      if (response.status === 200) {
        commit('updatePareja', response.data)
        dispatch('toast/showToast', { message: 'Pareja actualizada correctamente', color: 'success' }, { root: true })
        return response.data
      }
    } catch (error) {
      console.log("Error al actualziar pareja:", error)
      throw error
    }
  },
  async deletePareja({ commit, dispatch }, payload) {
    const { id } = payload
    try {
      const response = await axios.delete(`pareja/${id}/`)
      if (response.status === 204) {
        commit('deletePareja', id)
        dispatch('toast/showToast', { message: 'Pareja eliminada correctamente', color: 'success' }, { root: true })
      }
    } catch (error) {
      console.log("Error al eliminar pareja:", error)
      throw error
    }
  },
  /*-------------------------- END PAREJAS  --------------------------*/
  async addDatosPersonales({ dispatch }, payload) {
    const { rut } = payload
    await axios.put(`new_paciente/${rut}/`, payload)
    dispatch('toast/showToast', { message: 'Datos actualizados exitosamente', color: 'success' }, { root: true })

  },
  cleanData(context /* payload */) {
    const data = null;
    context.commit("setCD4", data);
    context.commit("setEncuestaPatient", data);
    context.commit("setTabVariables", true);
    context.commit("setTabPrediction", true);
    context.commit("setTabModelo", true);
    context.commit("setPacienteIntervencional", data);
  },

  async setPatient(context, payload) {
    //
    let patients = context.getters["patients"];
    if (patients.length === 0) {
      await context.dispatch("loadPatients");
      patients = context.getters["patients"];
    } else if (patients.length > 0) {
      patients = context.getters["patients"];
    } else {
      console.log("Hubo un problema");
    }
    const value = patients.filter((patient) => {
      return patient.codigoconasida === payload;
    });
    context.commit("setPatient", value);
  },

  async addEncuestaFast(context, payload) {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    let dataPatientEncuesta = payload.dataPatient;

    dataPatientEncuesta = {
      ...dataPatientEncuesta,
      paciente_nombre: payload.idPatient,
      register_poll_date: currentDate,
    };
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta/create", dataPatientEncuesta)
        .then((result) => {
          context.commit("setEncuestaPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta/update/" + payload.dataPatient.id + "/", dataPatientEncuesta)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async addEncuestaInfo(context, payload) {
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta_info_paciente/create", payload)
        .then((result) => {
          context.commit("setEncuestaInfoPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta_info_paciente/update/" + payload.id + "/", payload)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async addEncuestaLabo(context, payload) {
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta_laboral_habitacional/create", payload)
        .then((result) => {
          context.commit("setEncuestaLaboPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta_laboral_habitacional/update/" + payload.id + "/", payload)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async addEncuestaTrat(context, payload) {
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta_tratamiento/create", payload)
        .then((result) => {
          context.commit("setEncuestaTratPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta_tratamiento/update/" + payload.id + "/", payload)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async addEncuestaTras(context, payload) {
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta_trastornos/create", payload)
        .then((result) => {
          context.commit("setEncuestaTrasPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta_trastornos/update/" + payload.id + "/", payload)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async addEncuestaDrugs(context, payload) {
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta_drogas/create", payload)
        .then((result) => {
          context.commit("setEncuestaDrugsPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta_drogas/update/" + payload.id + "/", payload)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async addEncuestaObs(context, payload) {
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta_observacion/create", payload)
        .then((result) => {
          context.commit("setEncuestaObsPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta_observacion/update/" + payload.id + "/", payload)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async loadVariablesData(context, payload) {
    const idPatient = payload.idPatient;
    const result = await axios
      .get("paciente/api/detail/" + idPatient + "/")
      .then((result) => {
        context.commit("setVariable", result.data);
        //console.log(result.data);
        return result.data;
      })
      .catch((error) => console.log(error));
    return result;
  },
  async addVariablesFast(context, payload) {
    payload.dataPatient['mode_oth'] = "No responde"
    payload.dataPatient['ADQUISICION'] = 'SIN DATO'
    console.log(payload.dataPatient.Tiene_Autorizado)
    return await axios
      .put("paciente/api/update/" + payload.idPatient + "/", payload.dataPatient)
      .then((result) => {
        context.commit("setVariable", result.data);
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async getPatient(context, payload) {
    if (!payload) {
      console.log("Dispatching Undefined");
    }
    const response = await axios.get("/new_paciente/?codigoconasida=" + payload);
    context.commit("setPatient", response.data[0]);
  },
  cleanPatient(context) {
    context.commit("setPatient", null);
  },

  async loadCD4Data(context, payload) {
    context.commit("setLoading", true);
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/cd4/list/" + idPatient)
      .then((result) => {
        context.commit("setCD4", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setCD4", null);
      }).finally(() => {
        context.commit("setLoading", false);
      });
  },
  async loadCVData(context, payload) {
    context.commit('setLoading', true)
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/cv/list/" + idPatient)
      .then((result) => {
        context.commit("setCV", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setCV", null);
      }).finally(() => {
        context.commit('setLoading', false)
      });
  },
  async loadCD4ListData(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/cd4/list/" + idPatient)
      .then((result) => {
        context.commit("setCD4", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setCD4", null);
      });
  },
  async loadCVListData(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/cv/list/" + idPatient)
      .then((result) => {
        context.commit("setCV", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setCV", null);
      });
  },

  async addCD4(context, payload) {
    console.log("This is payload for adding cd4", payload);
    axios
      .post("paciente/api/cd4/create", payload)
      .then((response) => {
        context.dispatch("loadCD4ListData", { idPatient: response.data.paciente });
        console.log(response.data.paciente);
      })
      .catch((error) => console.log(error));
  },
  async addCV(context, payload) {
    console.log("This is payload for adding cv", payload);
    axios
      .post("paciente/api/cv/create", payload)
      .then((response) => {
        context.dispatch("loadCVListData", { idPatient: response.data.paciente });
      })
      .catch((error) => console.log(error));
  },
  async editCD4(context, payload) {
    axios
      .put("paciente/api/cd4/update/" + payload.id + "/", payload)
      .then((response) => {
        context.dispatch("loadCD4ListData", { idPatient: response.data.paciente });
      })
      .catch((error) => console.log(error));
  },
  async editCV(context, payload) {
    axios
      .put("paciente/api/cv/update/" + payload.id + "/", payload)
      .then((response) => {
        context.dispatch("loadCVListData", { idPatient: response.data.paciente });
      })
      .catch((error) => console.log(error));
  },
  async deleteCD4(context, payload) {
    console.log(payload);
    axios
      .delete("paciente/api/cd4/delete/" + payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  async deleteCV(context, payload) {
    console.log(payload);
    axios
      .delete("paciente/api/cv/delete/" + payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  /*----------------------------------Auditorias--------------------------------------------------------------------*/
  async loadAuditorias(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/auditoria/list/" + idPatient)
      .then((result) => {
        context.commit("setAuditorias", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setAuditorias", {});
      });
  },
  async editAuditorias(context, payload) {
    axios
      .put("paciente/api/auditoria/update/" + payload.id + "/", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
    console.log(payload);
  },
  async addAuditorias(context, payload) {
    console.log("This is payload", payload);
    axios
      .post("paciente/api/auditoria/create", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },

  /*---------Intervencional-------*/
  async loadIntervencional(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/intervencional/" + idPatient)
      .then((result) => {
        context.commit("setPacienteIntervencional", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPacienteIntervencional", {});
      });
  },

  async editIntervencional(context, payload) {
    return axios
      .put("paciente/api/intervencional/" + payload.paciente, payload)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log(error));
  },
  async addIntervencional(context, payload) {
    console.log("This is payload", payload);
    return axios
      .post("paciente/api/intervencional/" + payload.paciente, payload)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },

  loadTARlist(context, payload) {
    return axios
      .get("/paciente/api/tar/list/" + payload.idPatient)
      .then((result) => {
        context.commit("setTAR", result.data);
        return result;
      })
      .catch((error) => {
        console.log(error);
        context.commit("setTAR", {});
      });
  },
  async editTAR(contex, payload) {
    axios
      .put("/paciente/api/tar/update/" + payload.id + "/", payload)
      .then((result) => console.log(result))
      .catch((error) => {
        console.log("Error", error);
      });
  },
  async addTAR(context, payload) {
    console.log("This is payload", payload);
    axios
      .post("paciente/api/tar/create", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  /*-------------------------------------PAC-----------------------------------*/
  async loadPAC(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("/paciente/api/terapias_pac/list/" + idPatient)
      .then((result) => {
        context.commit("setPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPAC", {});
      });
  },
  async addPAC(context, payload) {
    console.log(payload);
    await axios
      .post("/paciente/api/terapias_pac/create", payload)
      .then((result) => {
        context.commit("setPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPAC", {});
      });
  },
  loadPACDetail(context, payload) {
    return axios
      .get("/paciente/api/terapias_pac/detail/" + payload)
      .then((result) => {
        console.log(result);
        context.commit("setTerapiaPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setTerapiaPAC", {});
      });
  },
  async editPAC(context, payload) {
    console.log(payload);
    await axios
      .put("/paciente/api/terapias_pac/update/" + payload.id + "/", payload)
      .then((result) => {
        context.commit("setPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPAC", {});
      });
  },
  async deleteTratamientoTAR(context, payload) {
    axios
      .delete("paciente/api/terapias_pac/delete/" + payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },

  /*-------------------------------------POP-----------------------------------*/
  async loadPOP(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("/paciente/api/terapias_pop/list/" + idPatient)
      .then((result) => {
        context.commit("setPOP", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPOP", {});
      });
  },
  async loadRNAData({ commit }, payload) {
    commit('setLoading', true)
    const { rut } = payload
    try {
      const response = await axios.get("paciente/rna/?paciente=" + rut)
      commit('SET_RNA', response.data)
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
    /*
        await axios
          .get("paciente/rna/?paciente=" + rut)
          .then((result) => {
            context.commit("setRNA", result.data);
            return result.data;
          })
          .catch(() => {
            context.commit("setRNA", null);
          }); */
  },
  async addRNA(context, payload) {
    const response = await axios.post("paciente/rna/", payload)
    return response.data
    /* axios
      .post("paciente/rna/", payload)
      .then((result) => console.log(result))
      .catch((error) => {
        const { data, status } = error.response
        console.log(data,status);
      }); */
  },
  async editRNA(context, payload) {
    const { id, body } = payload
    const response = await axios
      .put(`paciente/rna/${id}/`, body)
      .then((result) => { return result.data })
      .catch((error) => console.log(error));
    return response
  },
  async deleteRNA(context, payload) {
    const { id } = payload
    axios
      .delete(`paciente/rna/${id}`)
      .then((result) => console.log(result))
      .catch((error) => {
        const { response, status } = error
        console.log(response, status)
      });
  },
  /*----------------------------------PREDICTORES-------------------------------------*/
  predictEcosistema(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  predictDepresion(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  predictAyD(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  predictFarmacia(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  loadPredictions(context, payload) {
    return axios
      .get("/paciente/api/probabilidad/" + payload)
      .then((result) => {
        //console.log("This are the predictions", result);
        context.commit("setPredictions", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPredictions", {});
      });
  },
  /*----------------------------------DROP-------------------------------------*/
  async loadDrop(context, payload) {
    /* axios.get("paciente/api/drop/list/"+paciente) */
    context.commit("setDrop", { hola: payload });
  },

  /*---------------------------TABS----------------------------*/
  tabStatusAction(context, payload) {
    if (payload == "variables") {
      context.commit("setTabVariables", false);
    } else if (payload == "prediction") {
      context.commit("setTabPrediction", false);
    } else if (payload == "modelo") {
      context.commit("setTabModelo", false);
    }
  },
  tabStatusActionTrue(context, payload) {
    if (payload == "prediction") {
      context.commit("setTabPrediction", true);
    } else if (payload == "variables") {
      context.commit("setTabVariables", true);
    }
  },
  statusForm(context, payload) {
    console.log(payload);
    context.commit("setStatusForm", payload);
  },
  /*---------------------------fichaclinica----------------------------*/
  async put_fecha_ingreso_FA(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_fecha_nacimiento(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async updatePatient(context, payload) {
    const { rut } = payload
    try {
      const response = await axios.put('new_paciente/' + rut + '/', payload)
      if (response.statusText === 'OK') {
        context.commit('setNewPatientData', response.data)
        context.dispatch("toast/showToast", {
          message: `Datos actualizados`,
          tipo: "success",
        }, { root: true });
      } else {
        console.log('error', response);
      }
      return response
    }
    catch (error) {
      context.dispatch("toast/showToast", {
        message: `Error al actualizar los datos del paciente ${error}`,
        tipo: "error",
      }, { root: true });
    }


  },
  async put_fecha_enrolamiento(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_fecha_diagnosis(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_mode(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_mode_oth(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_art_baseline(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_cdcstage(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_aids(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_clinicaltrial(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_death_d(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async post_drop_d(context, payload) {
    console.log(payload);
    await axios
      .post("api/drop/create", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_policlinico(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_inicio_tar(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_condicion_especial(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_hla(context, payload) {
    console.log(payload);
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_matrona(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_prevision(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_geo(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_nivel_edu(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_edu(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_empleo(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_partner(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_sexcom(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_usecondom(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_frequsecondom(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_adquisicion(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_condusexual(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_autorizado(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
  async put_fecha_muerte(context, payload) {
    await axios
      .put("api/update/" + payload.rut + "/", payload)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log('falló : ', error);
      });
  },
};
