var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-5"},[_c('h1',{staticClass:"titulo-seccion-monitoreo"},[_vm._v("Otras enfermedades")]),_c('v-row',[_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v("Fecha diagnóstico")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v("Nombre enfermedad")]),_c('v-col',{staticClass:"subtitulo-seccion-monitoreo justify-center"},[_vm._v("Acciones")])],1),(_vm.otras_enfermedades.length > 0)?_c('v-container',{staticClass:"mt-3 overflow-auto elevation-1 rounded-lg",staticStyle:{"max-height":"400px"}},_vm._l((_vm.otras_enfermedades),function(otra_enfermedad,index){return _c('v-form',{key:index,ref:"form",refInFor:true,staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Fecha de diagnóstico","placeholder":"Fecha de diagnóstico","rules":[
                (v) =>
                  (v !== null && v !== undefined && v !== '') ||
                  'Falta fecha de diagnóstico',
              ],"required":""},model:{value:(otra_enfermedad.date),callback:function ($$v) {_vm.$set(otra_enfermedad, "date", $$v)},expression:"otra_enfermedad.date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menuState[index]),callback:function ($$v) {_vm.$set(_vm.menuState, index, $$v)},expression:"menuState[index]"}},[_c('v-date-picker',{attrs:{"max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),"min":"1950-01-01"},on:{"input":function($event){_vm.menuState[index] = false}},model:{value:(otra_enfermedad.date),callback:function ($$v) {_vm.$set(otra_enfermedad, "date", $$v)},expression:"otra_enfermedad.date"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"rules":[
            (v) =>
              (v !== null && v !== undefined && v !== '') ||
              'Falta la enfermedad',
          ],"label":"Nombre enfermedad"},model:{value:(otra_enfermedad.nombre),callback:function ($$v) {_vm.$set(otra_enfermedad, "nombre", $$v)},expression:"otra_enfermedad.nombre"}})],1),_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"orange-button ma-5",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addEnfermedad(otra_enfermedad, index)}}},[_vm._v(" Guardar ")]),_c('v-btn',{staticClass:"delete-button ma-5",on:{"click":function($event){$event.preventDefault();return _vm.deleteEnfermedad(otra_enfermedad)}}},[_vm._v(" Borrar ")])],1)],1)}),1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"blue-button ma-5",attrs:{"disabled":_vm.new_form},on:{"click":_vm.addFormEnfermedad}},[_vm._v(" +Agregar Enfermedad ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }