var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"contenedores-monitoreo"},[_c('v-card',{attrs:{"flat":""}},[_c('v-sheet',{staticClass:"pa-3 my-5 rounded-lg",attrs:{"color":"#F0F8F8"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between titulo-seccion-monitoreo"},[_vm._v(" Diagnóstico "),_c('v-btn',{attrs:{"outlined":"","color":"#1DC6D8"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Actualizar ")],1)],1),_c('v-card-subtitle',{staticClass:"subtitulo-seccion-monitoreo"},[_vm._v(" Verifique si los datos son correctos ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.sendData.apply(null, arguments)}}},[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Dianostico SIDA","prepend-icon":"mdi-calendar","disabled":_vm.patient.aids_y != 1,"rules":[_vm.validateDiagnosis],"outlined":""},model:{value:(_vm.patient.diagnosis_d),callback:function ($$v) {_vm.$set(_vm.patient, "diagnosis_d", $$v)},expression:"patient.diagnosis_d"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_d_diagnosis),callback:function ($$v) {_vm.menu_d_diagnosis=$$v},expression:"menu_d_diagnosis"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate,"min":"1950-01-01"},on:{"input":function($event){_vm.menu_d_diagnosis = false}},model:{value:(_vm.patient.diagnosis_d),callback:function ($$v) {_vm.$set(_vm.patient, "diagnosis_d", $$v)},expression:"patient.diagnosis_d"}})],1)],1),_c('v-col',[_c('v-select',{staticClass:"modify-input",attrs:{"required":"","rules":[
                (v) => (v !== null && v !== '') || 'Falta diagnóstico SIDA',
              ],"items":[
                { value: 1, text: 'Sí' },
                { value: 2, text: 'No' },
                { value: 9, text: 'Desconocido' },
              ],"label":"Diagnostico SIDA","outlined":""},model:{value:(_vm.patient.aids_y),callback:function ($$v) {_vm.$set(_vm.patient, "aids_y", $$v)},expression:"patient.aids_y"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"modify-input",attrs:{"required":"","rules":[(v) => !!v || 'Falta Mode'],"items":[
                { value: 1, text: 'Contacto Homosexual' },
                { value: 2, text: 'Usuario de drogas inyectables' },
                { value: 4, text: 'Hemofílico' },
                {
                  value: 5,
                  text: 'Transfusión, no relacionado con hemofilia',
                },
                { value: 6, text: 'Contacto Heterosexual' },
                { value: 8, text: 'Perinatal' },
                { value: 9, text: 'Contacto Sexual' },
                { value: 10, text: 'Bisexual' },
                { value: 90, text: 'Otro' },
                { value: 99, text: 'Desconocido' },
              ],"label":"Modo probable de infección","outlined":""},model:{value:(_vm.patient.mode),callback:function ($$v) {_vm.$set(_vm.patient, "mode", $$v)},expression:"patient.mode"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Otro modo de infección","outlined":"","clearable":"","disabled":_vm.patient.mode != 90},model:{value:(_vm.patient.mode_oth),callback:function ($$v) {_vm.$set(_vm.patient, "mode_oth", $$v)},expression:"patient.mode_oth"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"white--text px-10",attrs:{"type":"submit","color":"#F4A261"}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('v-sheet',{staticClass:"pa-3 my-5 rounded-lg",attrs:{"color":"#F0F8F8"}},[_c('CDCComponente')],1),_c('v-sheet',{staticClass:"pa-3 my-5 rounded-lg",attrs:{"color":"#F0F8F8"}},[_c('v-col',[_c('comorbilidades-componente'),_c('otras-enfermedades-componente')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }