<template>
  <v-sheet class="pa-3 my-5 rounded-lg" color="#F0F8F8">
    <h2 class="titulo-seccion-monitoreo">Pareja</h2>
    <v-row>
      <v-col class="subtitulo-seccion-monitoreo"> Nº pareja</v-col>
      <v-col class="subtitulo-seccion-monitoreo">Año del diagnostico</v-col>
      <v-col class="subtitulo-seccion-monitoreo">
        Estado serólogico del paciente
      </v-col>
      <v-col class="subtitulo-seccion-monitoreo justify-center">Acciones</v-col>
    </v-row>
    <v-container
      v-if="this.parejas.length > 0"
      class="mt-3 overflow-auto elevation-1 rounded-lg"
      style="max-height: 400px"
    >
      <v-form
        v-for="(pareja, index) in parejas"
        :key="index"
        @submit.prevent
        ref="form"
        class="d-flex"
      >
        <v-col cols="3">
          <v-text-field
            class="modify-input"
            :rules="[
              (v) =>
                /^[a-zA-ZÀ-ÿ\s0-9]+$/.test(v) ||
                'Solo se permiten letras, espacios y tildes',
            ]"
            outlined
            placeholder="Identificación"
            v-model="pareja.identificacion"
          />
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menuState[index]"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                label="Fecha de diagnóstico"
                placeholder="Fecha de diagnóstico"
                v-model="pareja.fecha_diagnostico"
                :rules="[
                  (v) =>
                    (v !== null && v !== undefined && v !== '') ||
                    'Falta fecha de diagnóstico',
                ]"
                required
              />
            </template>
            <v-date-picker
              v-model="pareja.fecha_diagnostico"
              :max="maxDate"
              min="1950-01-01"
              @input="menuState[index] = false"
            />
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-select
            class="modify-input"
            required
            :rules="[
              (v) => (v !== null && v !== undefined) || 'Falta clinicaltrial',
            ]"
            :items="[
              { value: 99, text: 'Sin datos' },
              { value: 1, text: 'Pareja VIH (+)' },
              { value: 2, text: 'Pareja VIH (-)' },
              { value: 3, text: 'Desconocido' },
            ]"
            label="Paciente derivado desde otro centro"
            outlined
            v-model="pareja.estado_serologico"
          />
        </v-col>
        <v-col cols="3" class="d-flex justify-center">
          <v-btn
            type="submit"
            class="orange-button ma-5"
            @click="savePareja(pareja, index)"
          >
            Guardar
          </v-btn>
          <v-btn
            class="delete-button ma-5"
            @click.prevent="deletePareja(pareja)"
          >
            Borrar
          </v-btn>
        </v-col>
      </v-form></v-container
    >
    <v-row class="d-flex justify-end">
      <v-btn
        class="blue-button ma-5"
        @click="addFormPareja"
        :disabled="new_form"
      >
        +Agregar Pareja
      </v-btn>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "ParejasComponente",
  data() {
    return {
      parejas: [],
      new_form: false,
    };
  },
  computed: {
    menuState() {
      return this.parejas.map(() => false);
    },
    patient() {
      return this.$store.getters["patients/getPatient"];
    },
    maxDate() {
      return new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString();
    },
  },
  methods: {
    async savePareja(pareja, index) {
      const valid = this.$refs.form[index] && this.$refs.form[index].validate();
      if (!valid) return;
      const { id, identificacion, fecha_diagnostico, estado_serologico } =
        pareja;
      try {
        if (id) {
          await this.$store.dispatch("patients/updatePareja", {
            id,
            body: { identificacion, fecha_diagnostico, estado_serologico },
          });
        } else {
          const { id: newId } = await this.$store.dispatch(
            "patients/addPareja",
            {
              paciente: this.patient.rut,
              ...pareja,
            }
          );
          const existingIndex = this.parejas.findIndex(
            (p) => p.id === pareja.id
          );
          if (existingIndex !== -1) {
            this.$set(this.parejas, existingIndex, {
              ...pareja,
              id: newId,
            });
          } else {
            this.parejas.push({ ...pareja, id: newId });
          }
        }
      } catch (error) {
        console.error("Error updating pareja data:", error);
      }
      this.new_form = false;
    },
    deletePareja({ id }) {
      if (id) {
        this.$store.dispatch("patients/deletePareja", { id });
      } else {
        this.new_form = false;
      }

      this.parejas = this.parejas.filter((pareja) => pareja.id !== id);
    },
    addFormPareja() {
      this.parejas.push({
        identificacion: "",
        fecha_diagnostico: "",
        estado_serologico: 99,
      });
      this.new_form = true;
    },
  },
  async created() {
    try {
      const { id } = this.$route.params;
      const response = await this.$store.dispatch("patients/loadParejas", {
        codigoconasida: id,
      });
      this.parejas = response.data.results;
      if (this.parejas.length === 0) {
        this.addFormPareja();
        this.new_form = true;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
